<template>
  <div>
      <h2 class="has-text-weight-bold">Rating Progression</h2>
      <div id="elo_progression">
        <button class="copy-icon">Copy to Clipboard</button>
        <button v-on:click="handleText" id="buttonID">
          test text
        </button>
        <button v-on:click="handleScreenshot" id="buttonID2">
          test img
        </button>

        <vn-line :model="eloProgression" :x-format="formatDate" y-format=",f" :height="'500'" x-axis-label="Date" y-axis-label="Rating">
        </vn-line>
      </div>
  </div>
</template>

<script>
import d3 from 'd3'
import html2canvas from 'html2canvas'

export default {
  name: 'FakeStats',
  data: function () {
    return {
      eloData: [],
      timeSpent: [],
      gameTimeSpentDict: {},
      minMaxElo: [0, 2500],
      minMaxTime: [1645050955273, 1645050955273]
    }
  },
  computed: {
    eloProgression: function() {
      return [
        {
          key: 'Your Rating',
          bar: true,
          values: [
              {x: 100, y: 1000},
              {x: 120, y: 2000},
              {x: 150, y: 1500},
              {x: 180, y: 800},
              {x: 200, y: 2000},
            ]
        }
      ];
    },
  },
  mounted() {
    console.log('gameStats mounted');    
    var copyIcon = document.querySelector('.copy-icon');
    copyIcon.addEventListener("click", function(e) {
      e.preventDefault();
      
      // get the node
      var domNode = document.getElementById('elo_progression');
      domNode.classList.add("on");
      
      /*
      navigator.permissions.query({name:'clipboard-write'}).then(function (permStatus) {console.log(permStatus)})
      navigator.permissions.query({name:'clipboard-read'}).then(function (permStatus) {console.log(permStatus)})
      */

      // copy the canvas to the clipboard with chrome's CliboardItem API
      // https://developers.google.com/web/updates/2019/07/image-support-for-async-clipboard#images
      html2canvas(domNode).then(function(canvas) {
        console.log('starting copy');

        canvas.toBlob(function(blob) {
          console.log('inside canvas.toBlob:' + blob);

          navigator.clipboard.write([
            new window.ClipboardItem({
              [blob.type]: blob
            })
          ])
            .then(function() {
            console.log("Copied to clipboard");
            domNode.classList.remove("on");
          });
        });
      });
    });
  },
  methods: {
    render: function (m) {
      console.log(m);
    },
    formatDate (d) {
      return d3.time.format('%x')(new Date(d));
    },
    toMinute (d) {
      return d/1000/60; // ms to minutes
    },
    handleText() {
      async function screenShot() {
        try {
          navigator.clipboard.writeText(
            'asdsad'
          );
        } catch (err) {
          console.error(err);
        }
      }
      screenShot();
    },    
    handleScreenshot() {
      async function screenShot() {
        try {
          const response = await fetch(
            "https://i.postimg.cc/d0hR8HfP/telefono.png"
          );
          const blob = await response.blob();
          console.log(blob);
          await navigator.clipboard.write(
          [
            new window.ClipboardItem({ "image/png": blob }),
          ]);
        } catch (err) {
          console.error(err);
        }
      }
      screenShot();
    }

  }
}
</script>
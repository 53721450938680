<template>
  <div class="home">
    <div v-if="justCreated">   
      <!-- removed as it was distracting
      <welcome>test</welcome>
      -->

      <!--
      <br>
      <a href="https://mathleet.s3-us-west-2.amazonaws.com/tutorial/example_game1.mp4">Tutorial Video</a>
      <img src="https://mathleet.s3-us-west-2.amazonaws.com/tutorial/tutorial.gif">
      -->
    </div>
  
    <!-- 
    <Lobby v-if="$auth.isAuthenticated"/>
    -->
    <Lobby/>

  <!--
    <p>
      When \(a \ne 0\), there are two solutions to \(ax^2 + bx + c = 0\) and they are 
      $x = {-b \pm \sqrt{b^2-4ac} \over 2a}.$
    </p>
  -->
  </div>

</template>

<script>
// @ is an alias to /src
import Lobby from '@/components/Lobby.vue'
//import welcome from '@/components/welcome2.vue'

export default {
  name: 'Home',
  components: {
    //Lobby, welcome
    Lobby
  },
  data: function() {
    return { justCreated:false }
  },
  created: function() {
    this.axios.get("https://mathleet.s3-us-west-2.amazonaws.com/cards.json").then((res) => {
      this.$store.commit("saveCarddb", res.data)
    })

  },
  mounted: function() {
    window._MathJax.typeset()
  },
  /* for triggering just created msg/help
  beforeRouteEnter (to, from, next) {
    console.log("beforeRouteEnter;" +to.path +";" +from.path); 

    next(vm => {
      next();

      console.log(vm)
      console.log(next)

      if (from.path === '/createUser') {
        vm.justCreated = true;
      } else {
        vm.justCreated = false;
      }
    });
  }
  */
}
</script>

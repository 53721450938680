<template>
<div class="container">
  <div id="app">
    <div id="nav">
<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">

    <router-link class='navbar-item' to="/">
    <span class="navbar-item">
      <span class="logo">
        <img src="@/../public/Sigma_rotated_flipped.png">
        <br>
        mathleet
      </span>
    </span>
    </router-link>

    <a role="button" v-if="!$store.state.ingame" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <router-link class='navbar-item' v-if ='!$store.state.ingame' to="/About">About</router-link>
      <router-link class='navbar-item' v-if="!$store.state.ingame" to="/lobby">Lobby</router-link>
      <router-link class='navbar-item' v-if="!$store.state.ingame" to="/leaderBoard">Leaderboard</router-link> 
      <router-link class='navbar-item' v-if="!$store.state.ingame && $auth.isAuthenticated" to="/profile">Profile</router-link>
      <router-link class='navbar-item' v-if="!$store.state.ingame && $auth.isAuthenticated" to="/myStats">My Stats</router-link>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <button class="button is-primary" v-if="! $auth.isAuthenticated" @click="login">Log in</button>
          <button class="button" v-if="!$store.state.ingame && $auth.isAuthenticated" @click="logout">Log out</button>
        </div>
      </div>
    </div>
  </div>
</nav>
    </div>
<keep-alive>
    <router-view/>
</keep-alive>
  </div>
</div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}
#nav {
  padding-top:0;
  padding-bottom:0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>

import 'bulma/css/bulma.css'
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'
import '@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css'

export default {
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>


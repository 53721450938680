import d3 from 'd3'
import nv from 'nvd3'
import BaseChartMixin from './BaseChartMixin'

export default {
  name: 'MultiBarChart',
  mixins: [BaseChartMixin],
  props: {
    xAxisLabel: {type: String},
    yAxisLabel: {type: String},
    textField: {type: String, default: 'label'},
    valueField: {type: String, default: 'value'},
    staggerLabels: {type: Boolean, default: true},
    xFormat: {type: [Function, String]},
    yFormat: {type: [Function, String]},
    showValues: {type: Boolean, default: true},
    colors: {type: Array, default: () => ['#82DFD6', '#ddd']},
    tooltips: {type: Boolean, default: true},    
    toolTip: {type: [Function, String]}
  },
  mounted () {
    const textField = this.textField
    const valField = this.valueField

    nv.addGraph(() => {

      const chart = nv.models.multiBarChart()
        .x(d => d[textField])
        .y(d => d[valField])
        //.colors(this.colors)
        //.staggerLabels(this.staggerLabels)    //Too many bars and not enough room? Try staggering labels.
        //.tooltips(this.tooltips)              // Don't show tooltips
        //.showValues(this.showValues)          //...instead, show the bar value right on top of each bar.
        //.transitionDuration(350)
      
      chart.xAxis.axisLabel(this.xAxisLabel)
      chart.yAxis.axisLabel(this.yAxisLabel)
      
      const xaxis = chart.xAxis
      if (this.xFormat) {
        if (typeof(this.xFormat) === 'string') {
          xaxis.tickFormat(d3.format(this.xFormat))
        } else {
          xaxis.tickFormat(this.xFormat)
        }
      }

      const yaxis = chart.yAxis
      if (this.yFormat) {
        if (typeof(this.yFormat) === 'string') {
          yaxis.tickFormat(d3.format(this.yFormat))
        } else {
          yaxis.tickFormat(this.yFormat)
        }
      }
      
      // otherwise use chart.interactiveLayer.contentGenerator
      if (this.toolTip) {
        console.log(this.toolTip);
        if (typeof(this.toolTip) === 'string') {
          chart.tooltip.contentGenerator(function() { return this.toolTip});
        } else {
          chart.tooltip.contentGenerator(this.toolTip);
        }
      }

      this.redraw(chart)
      this.chartRef = chart

      nv.utils.windowResize(chart.update);
      return chart
    })
  }
}

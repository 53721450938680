<template>
  <div class="hello">
    <arrangeFormModal v-if="showModal" @close="showModal = false" :challenged="gameU" :isSolo="isSolo" v-on:submittedArrangeOptions="challengeHelper">
    </arrangeFormModal>

    <div :class="[showHelp ? 'is-active' : '', 'modal']" style="width: calc(100vw-40px);">
      <div class="modal-background"></div>
      <div class="modal-card" style="width: calc(100vw - 80px);">
        <header class="modal-card-head">
          <p class="modal-card-title">Lobby Demo</p>
          <button class="delete" aria-label="close" v-on:click="showHelp=false"></button>
        </header>
        <section class="modal-card-body">
          <h2 class="has-text-weight-bold">Play games in solo mode.</h2>
          <h2 class="has-text-weight-bold">Or challenge other users to games. </h2>
          <figure class="image"> 
            <img src="https://mathleet.s3-us-west-2.amazonaws.com/tutorial/lobbyDemo2.png">
          </figure>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>
    
    <a href="javascript:void(0)" class="has-tooltip-arrow has-tooltip-bottom is-pulled-right" style="color: #3273dc;" data-tooltip="Click for lobby demo" v-on:click="showHelp=true">
      <i class="fas fa-question-circle"></i>
    </a>

    <div id="anonMsg" v-if="!$auth.isAuthenticated" >
      <span style="line-height: 2.5rem; display: inline-flex; align-items: center;">Register or login to play rated games, unlock calibrated problems, move up the leaderboard and track your progress.</span>
      <button class="button is-primary" data-tooltip="Register/login" @click="login">Log in</button>
    </div>

    <div class="tile is-ancestor"> 
      <div class="tile is-parent is-flex-wrap-wrap">
        <div class="tile is-child is-2" v-for="u in lobbyUsers" :key="u.email" >
          <div class='box m-2' v-bind:class="{selfClass:isSelf(u)}" style='height:130px;width:140px'>
            <img width="24" v-bind:src="'https://www.countryflagsapi.com/svg/' + u.country " v-if="u.country!='NAN'"/> 
            {{u.handle}} <br/> 
            {{u.elo}} <br/> 

            <div class="dropdown" v-if="!isSelf(u)" v-bind:disabled="u.await" v-bind:class="[{'is-hoverable':!u.await}]">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" v-bind:aria-controls="'dropdown_'+u.handle" v-bind:class="[{'is-warning':u.await}, {'is-danger':u.challenged}, 'button', 'is-light']" style="max-width:8rem; white-space:inherit;">
                  <span v-if="!u.challenged">Challenge</span>
                  <span v-if="u.challenged" class="has-tooltip-arrow has-tooltip-right" v-bind:data-tooltip="'Accept ' +u.challengeType +' challenge from ' +u.challenger" v-on:click="challenge(u, u.challengeType, u.challengeOptions)">Accept {{u.challengeType}}</span>
                  <span v-if="u.challenged" class="has-tooltip-arrow has-tooltip-right" v-bind:data-tooltip="'Reject ' +u.challengeType +' challenge from ' +u.challenger" v-on:click="reject_challenge(u, u.challengeType, u.challengeOptions)">Reject {{u.challengeType}}</span>
                </button>
              </div>
              <div class="dropdown-menu" v-bind:id="'dropdown_'+u.handle" role="menu" v-if="!u.challenged">
                <div class="dropdown-content" style="max-width:8rem; white-space:inherit;">
                  <div v-for="gt in gameTypes.slice(0, 4)" :key="gt" class="dropdown-item has-tooltip-arrow has-tooltip-right" style="color:#42b983;" v-bind:data-tooltip="'Challenge ' +u.handle +' to a game of ' +gt"   >
                    <div v-if="gt=='Arrange'" @click="showModal = true; gameU=u; isSolo=false;" >
                      {{gt}}
                   </div>
                   <div v-else v-on:click="challenge(u, gt)">
                      {{gt}}
                    </div>
                  </div>
                  <!--
                  <hr class="dropdown-divider">
                  -->
                </div>
              </div>
            </div>  <!-- end challenge dropdown -->
            <div class="dropdown" v-else v-bind:class="['is-hoverable']">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" v-bind:aria-controls="'dropdown_'+u.handle" v-bind:class="['button', 'is-light']" style="max-width:8rem; white-space:inherit;">
                  <span>Solo</span>
                </button>
              </div>
              <div class="dropdown-menu" v-bind:id="'dropdown_'+u.handle" role="menu">
                <div class="dropdown-content" style="max-width:8rem; white-space:inherit;">
                  <div v-for="gt in gameTypes" :key="gt" class="dropdown-item has-tooltip-arrow has-tooltip-right" style="color:#42b983;" v-bind:data-tooltip="getTooltip(gt)"   >
                    <div v-if="gt=='Arrange'" @click="showModal = true; gameU=u; isSolo=true;" >
                      {{gt}} 
                   </div>
                   <div v-else v-on:click="play(u, gt)">
                      {{gt}}
                    </div>
                  </div>
                  <!--
                  <hr class="dropdown-divider">
                  -->
                </div>
              </div>
            </div>  <!-- end play dropdown -->
            
            <span v-if="u.await" class="has-tooltip-arrow has-tooltip-right" v-bind:data-tooltip="'Cancel ' +u.challengeType +' challenge to ' +u.handle" v-on:click="cancel_challenge(u, u.challengeType, u.challengeOptions)">Cancel</span>

          </div>
        </div>  <!-- end users -->
      </div>
    </div>
  </div>
</template>

<script>
//import Socket from '@/socket'
import arrangeFormModal from '@/components/arrangeFormModal.vue'
const raceSuffix = ' (Race)';
const BASE_ANON_EMAIL = "anon@mathleet.com"
const BASE_ANON_USER = {"email":BASE_ANON_EMAIL}

export default {
  name: 'Lobby',
  components: {
    arrangeFormModal 
  },
  data: function() {
    return { 
      users: [], 
      hearbeat:null, 
      connUser: null,
      me:null, 
      showModal:false, 
      showHelp:false,
      gameTypes:['Arithmetic', 'Arrange', '24', 'Solvex', '24'+raceSuffix, 'Solvex'+raceSuffix], 
    };
  },
  created: function() { 
    console.log("Lobby created");
    this.connUser = this.$auth.isAuthenticated ? this.$auth.user : BASE_ANON_USER;

    this.$socket.onmessage =  this.render;
    this.$socket.onopen =  this.socketReady;
    
    //this.heartbeat = setInterval(beat, 20000);
  },
  beforeDestroy: function() {
    console.log("Lobby destroyed");
    //clearInterval(this.heartbeat);
    //this.$disconnect();
    //document.vue.$disconnect();
  },  
  activated: function() {
    console.log("lobby activated, rebinding render");
    this.connUser = this.$auth.isAuthenticated ? this.$auth.user : BASE_ANON_USER;

    this.$socket.onmessage =  this.render;
    if (this.$socket.readyState == 1) {
      this.socketReady();
    }
    
    clean_tooltips();
    this.enterSound();
  },
  deactivated: function() {
    console.log("lobby deactivated!");
    this.$socket.sendObj({"method":"leaveLobby", "payload": {"email": this.connUser.email}});

    //this.exitSound();
  },
  methods: { 
    socketReady: function() {
      //console.log(this.$socket);
      this.$socket.sendObj({"method":"updateUser", "payload": {"email": this.connUser.email}});
    },
    isSelf: function(u) {
      return u.email == this.connUser.email || u.email==this.me.email;
    },
    render: function (m) {
      m = JSON.parse(m.data);
      console.log("render=" + m.m);
      if (m.m == "allusers") {
        m.d.forEach (u => {
          const i = this.users.findIndex((e) => e.email == u.email) // TODO: annoyingly inefficient
          if (i == -1) {
            u.await = false;
            u.challenged = false;
          } else {
            u.await = this.users[i].await;
            u.challenged = this.users[i].challenged;
          }
          if (this.connUser.email == u.email || m.cid==u.cid) { 
            this.me = u;
            if (this.me.email.includes("mathleet.com")) {
              this.me.handle = 'You';
            }
          }
        })
        console.log("updating users");
        this.users = m.d;
      } else if (m.m == "challenge") {
        console.log(m);

        const i = this.users.findIndex((e) => e.cid == m.d); // TODO: annoyingly inefficient
        if (i != -1) {
          let u = this.users[i];
          u.await = false;
          u.challenged = true;
          u.challenger = m.challenger;
          u.challengeType = m.gameType;
          u.challengeOptions = m.gameOptions;

          this.playSound();
        }
      } else if (m.m == "reject_challenge") {
        console.log(m);
        
        const i = this.users.findIndex((e) => e.cid == m.d); // TODO: annoyingly inefficient
        if (i != -1) {
          let u = this.users[i];
          // console.log("reject_challenge2;" + JSON.stringify(u));
          u.await = false;
        }
      } else if (m.m == "cancel_challenge") {
        console.log(m);
        
        const i = this.users.findIndex((e) => e.cid == m.d); // TODO: annoyingly inefficient
        if (i != -1) {
          let u = this.users[i];
          //console.log("cancel_challenge2;" + JSON.stringify(u));
          u.challenged = false;
        }
        
      } else if (m.m == "start") {
        console.log("START GAME!");
        console.log(m.d);        
        this.setGameStates(m);

        this.users.forEach (u => {
          u.await = false;
          u.challenged = false;
        })
        this.$router.push('playGame');
      } else if (m.m == "userNotExist") {
        console.log("userNotExist")
        this.$router.push('/createUser');
      }
    },
    setGameStates: function(m) {
      this.me.cards = m.u[0].cid == this.me.cid ? JSON.parse(m.u[0].cards):JSON.parse(m.u[1].cards);
      const state = {
        me: this.me,
        opponent: {
          cid: m.u[0].cid != this.me.cid ? m.u[0].cid:m.u[1].cid,
          email: m.u[0].cid != this.me.cid ? m.u[0].email:m.u[1].email,
          handle: m.u[0].cid != this.me.cid ? m.u[0].handle:m.u[1].handle,
          cards: m.u[0].cid != this.me.cid ? JSON.parse(m.u[0].cards):JSON.parse(m.u[1].cards)
        },
        problems: m.p.problems,
        curProblemIndex: m.p.curProblemIndex,
        gameType: m.d.gameType,
        status: "start",
        gameOptions: m.d.gameOptions,
        isRace: m.d.isRace,
        startELO: m.d.startELO,
        gameStartTime: m.t,
        gameProgress: m.gp
      }
      this.$store.commit('setGame', state);
    },
    challengeHelper: function(o) {
      if (this.isSelf(o.u)) {
        this.play(o.u, o.gameType, o);
      } else {
        this.challenge(o.u, o.gameType, o);
      }
      this.showModal = false;
    }, 
    challenge: function(u, gameType="Arithmetic", gameOptions={}) {
      console.log("in challenge(); u=" +JSON.stringify(u));

      if (u.challenged) {      
        this.play(u, gameType, gameOptions);
      } else {
        u.await = true;
        this.$socket.sendObj({"method":"challenge", "payload":{"other":u.cid, "challenger":this.me.handle, "gameType":gameType, "gameOptions":gameOptions}});
      }
    },
    reject_challenge: function(u, gameType="Arithmetic", gameOptions={}) {
      console.log("in reject_challenge(); u=" +JSON.stringify(u));

      this.$socket.sendObj({"method":"reject_challenge", "payload":{"other":u.cid, "challenger":u.handle, "challenged":this.me.handle, "gameType":gameType, "gameOptions":gameOptions}});
      u.challenged = false;
    },    
    cancel_challenge: function(u, gameType="Arithmetic", gameOptions={}) {
      console.log("in cancel_challenge(); u=" +JSON.stringify(u));

      this.$socket.sendObj({"method":"cancel_challenge", "payload":{"other":u.cid, "challenger":this.me.handle, "challenged":u.handle, "gameType":gameType, "gameOptions":gameOptions}});
      u.await = false;
    }, 
    play: function(u, gameType="Arithmetic", gameOptions={}) {
      let isRace = gameType.includes(raceSuffix);
      let realGameType = gameType.replace(raceSuffix, "");
      this.$socket.sendObj( {"method":"startGame", "payload":{"p1":this.me.cid, "p2":u.cid, "p1e":this.me.email, "p2e":u.email, "gameType":realGameType, "gameOptions":gameOptions, "isRace":isRace}, } );
    },
    playSound () {
      var audio = new Audio('https://mathleet.s3-us-west-2.amazonaws.com/audio/alert.wav');
      audio.play();
    },
    enterSound () {
      var audio = new Audio('https://mathleet.s3-us-west-2.amazonaws.com/audio/buddyin.wav');
      audio.play();
    },
    exitSound () {
      var audio = new Audio('https://mathleet.s3-us-west-2.amazonaws.com/audio/buddyout.wav');
      audio.play();
    },    
    mouseOver: function(){
      this.gameFormActive = !this.gameFormActive;
    },
    compare: function(a, b) {
      if (a.email === this.me.email) {
        return -1;  // a before b
      } else if (b.email === this.me.email) {
        return 1;  // b before a
      } else { // order by elo to this.me.elo if not this.me
        return Math.abs(a.elo - this.me.elo) - Math.abs(b.elo - this.me.elo);
      }
    },
    getTooltip(gt) {
      let tipStr = "";
      switch(gt) {
        case "Arithmetic":
          tipStr = "Hone your arithmetic skills";
          break;
        case "Arrange":
          tipStr = "Arrange numbers and operators correctly";
          break;
        case "24":
          tipStr = "Arrange numbers and operators to make 24";
          break;
        case "24 (Race)":
          tipStr = "Solve as many 24 problems as you can in 5min";
          break;
        case "Solvex":
          tipStr = "Solve challenging problems calibrated for your rating";
          break;
        case "Solvex (Race)":
          tipStr = "Solve as many challenging problems as you can in 5min";
          break;
        default:
          tipStr = "Play a game of " + gt;
      }
      return tipStr;
    },
    login() {
      this.$auth.loginWithRedirect();
    },
  },
  computed: {
    lobbyUsers: function() {
      return this.users.slice().sort(this.compare);
    }
  },
}
/*
function beat() {
  console.log("ping");
  //document.store.state.connection.send(JSON.stringify({"method": "heartbeat", "payload":{}})); // TODO: hack should find a way not to use document later (same for the $auth lines above);
  document.vue.$socket.send(JSON.stringify({"method": "heartbeat", "payload":{}})); 
}
*/

function clean_tooltips() {
  let  dom_tooltips = document.getElementsByClassName("nvtooltip");
  // console.log("in clean_tooltips: " + dom_tooltips.length)
  for (let i=0; i<dom_tooltips.length; i++) {
    // dom_tooltips[i].style.display = "none";
    dom_tooltips[i].remove();
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.selfClass {
  color: blue;
}
</style>

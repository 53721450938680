<template>
  <div class="fluid container" v-bind:class="[{'greyout':!editable}]">
    <div class="draggableDisplay">
      <div class="field is-horizontal columns is-mobile">
        <div class="field-label is-normal column is-offset-one-quarter" style="flex:none; padding-right:0.0rem; "> 
          <label class="label" v-if="gameType=='24'">
            Eval:
          </label>
          <label class="label" v-else>
            Correct?
          </label>
        </div>
        <div class="field">
          <p class="control is-expanded has-icons-left">
            <input class="input" name="userInput" type="text" :placeholder="computedExpression" style="display:none"/>
            <input id="valCheck" type="text" class="input is-expanded has-icons-left column is-one-half" name="evalExpr" :value="evalExpr" disabled />
          </p>
        </div>
        <div class="field">
          <p class="control is-expanded has-icons-left has-icons-right">
            <button class="button is-warning" @click="reset" data-tooltip="Reset to initial numbers">Reset</button>
          </p>
        </div>
        <!--
        <div class="checkbox">
          <label><input type="checkbox" v-model="editable">Enable drag and drop</label>
        </div>
        -->
      </div>  <!-- end field div -->
    </div>    <!-- end draggableDisplay div -->
    
    <div class="draggableList is-mobile">
      <draggable class="level drag24" tag="nav" v-model="list" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" group="operators" id="expr_bar" :empty-insert-threshold="100" >
        <transition-group type="transition" :name="'flip-list'" class="level-item columns is-mobile drag24_tg" v-if="gameType=='24'">
          <!--
          <p v-for="(element, index) in list" :key="index" v-html="element.name" class="level-item unselectable elem_style" :class="[element.is_operator ? 'operator24': 'number24']" v-bind:style= "[!element.is_operator ? {'background-image': 'linear-gradient(to bottom left, hsl(210,100%,97%), hsl(210,100%,' +(0.97-(parseInt(element.name)-2)/50-.25)*100 +'%), hsl(210,100%,' +(0.97-(parseInt(element.name)-0)/50-.25)*100 +'%))' } : '']">
          -->
          <p v-for="(element, index) in list" :key="index" v-html="element.name" class="level-item unselectable elem_style" :class="[element.is_operator ? 'operator24': 'number24']" v-bind:style= "[!element.is_operator ? {'background-image': 'linear-gradient(to bottom left, hsl(210,100%,95%), hsl(210,100%,70%))' } : '']">
            <!--<i :class="element.fixed? 'fa fa-anchor' : 'fas fa-thumbtack'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>-->
            {{element.name}}
          </p>
        </transition-group>
        <transition-group type="transition" :name="'flip-list'" class="level-item columns is-mobile drag24_tg" v-else>
          <p v-for="(element, index) in list" :key="index" v-html="element.name" class="level-item unselectable elem_style" :class="[element.is_operator ? 'operator24': 'number24']" v-bind:style= "[!element.is_operator ? {'background-image': 'linear-gradient(to bottom left, hsl(210,100%,95%), hsl(210,100%,70%))' } : '']">
            {{element.name}}
          </p>
        </transition-group>
      </draggable>
      
      <draggable class="level drag24" tag="nav" :value="list2" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false"  :group="{ name:'operators', pull:'clone', put:true }" id="operator_bar" :empty-insert-threshold="100">
        <transition-group name="no" class="level-item columns is-mobile drag24_tg">
          <p v-for="(element, index) in list2" :key="index" v-html="element.name" class="level-item unselectable elem_style"  :class="[element.is_operator ? 'operator24': 'number24']" >
            <!--<i :class="element.fixed? 'fa fa-anchor' : 'fas fa-thumbtack'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>-->
            {{element.name}}
          </p>
        </transition-group>
      </draggable>
    </div>
        
    <!--
    <div class="list column is-one-quarter">
      <pre>{{listString}}</pre>
    </div>
    <div class="list column is-one-quarter">
      <pre>{{list2String}}</pre>
    </div>
    -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
//const operators = ["+", "-", "*", "/", "^", "(", ")"];
//const operators = ["+", "-", "&times;", "&divide;", "^", "(", ")"];

export default {
  name: "draggable24",
  display: "Transition",
  components: {
    draggable
  },
  props: {
    gameType:{String, required:true},
    numbers:{Array, required:true},
    operators: {Array, required:true},
  },
  data() {
    return {
      editable:true,
      isDragging:false, 
      delayedDragging:false,
      list: this.numbers.map((name, index) => {
        return { name, order: index + 1, fixed: false, is_operator:false};
      }),
      list2: this.operators.map((name, index) => {
        return { name, order: index + 1, fixed: false, is_operator:true};
      }),
    };
  },
  methods: {
    reset() {
      this.list = this.numbers.map((name, index) => {
        return { name, order: index + 1, fixed: false};
      });
    },
    orderList() {
      this.list = this.list.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const relatedOriginBarID = relatedContext.component.$attrs.id;
      const draggedElement = draggedContext.element;

      if (!draggedElement.is_operator) {
        if (relatedOriginBarID=="expr_bar") {
          return ( (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed );
        } else {
          return false;
        }
      } else {  // operators can be dragged anywhere
        return ( (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed );
      }
    },
    setNumbers(numbers) {
      this.numbers = numbers;
      this.list = this.numbers.map((name, index) => {
        return { name, order: index + 1, fixed: false};
      });
    },    
    setOperators(ops) {
      this.operators = ops;
      this.list2 =  this.operators.map((name, index) => {
                    return { name, order: index + 1, fixed: false, is_operator:true};
                    });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    /*
    list2: function() {
      const temp =  this.operators.map((name, index) => {
                    return { name, order: index + 1, fixed: false, is_operator:true};
                    });
      return temp;
    },
    */
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    list2String() {
      return JSON.stringify(this.list2, null, 2);
    },
    computedExpression() {
      let curExpr = this.list.map(function (elem) {
        return elem.name;
      }).join(" ");
      
      curExpr = curExpr.replace(/&times;/g, '*');
      curExpr = curExpr.replace(/&divide;/g, '/');
      this.$emit("draggableExpr", {"computedExpression":curExpr});
      return curExpr;
    },
    evalExpr() {
      if (this.gameType=='24') {
        try {
          return eval(this.computedExpression.replace(/\^/g, '**'));
        } catch (e) {
          return this.computedExpression;
        }
      } else {
        let compExp = this.computedExpression.replace(/\^/g, '**');
        let exprToks = compExp.split('=');
        let left_side = exprToks[0],
            right_side = exprToks[1];
        try {
          if (eval(left_side)==eval(right_side)) { 
            return "Correct";
          } else {
            return "Incorrect";
          }
        } catch (e) {
          return "Incorrect";
        }
      }
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  }
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.level {
  min-height: 20px;
}

.number24 {
  font-size: 1.5rem;
  padding: 1.0rem;
  margin: 0.25rem;
  max-width: 3.24rem;
  border-radius: 10%;
  box-shadow: 0 0.15rem 0.35rem -0.125rem rgba(10, 10, 10, 1.0), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.number24:hover {
  box-shadow: 0 0.3rem 0.7rem -0.125rem rgba(10, 10, 10, 1.0), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  transition: all 0.5s;
  cursor: grab;
}
.number24:active {
  cursor: grabbing;
}
.operator24 {
  font-size: 1.6rem;
  padding: 0.75rem;
  margin: 0.25rem;
  height: 2.75rem;
  width: 2.25rem;
  border-radius: 50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  box-shadow: 0 0.15em 0.35em -0.125em #3273dc, 0 0px 0 1px #96d5ff;
  transition: all 0.25s;
}
.operator24:hover {
  box-shadow: 0 0.3em 0.7em -0.125em #3273dc, 0 0px 0 1px #96d5ff;
  transition: all 0.5s;
  cursor: grab;
}
.operator24:active {
  cursor: grabbing;
}
.greyout {
  background-color: whitesmoke;
}
.drag24 {
  display:block;
  /* display:grid;  */
  justify-content:center;
  margin-bottom:1.0rem;  
  /* border: solid;  */
  /* padding-top:0.5rem; */
  padding:1rem;
  padding-bottom:0rem;
}
.draggableList {
  padding:1rem 0rem 1rem 0rem;
}
.drag24_tg {
  flex-wrap: wrap;
}
.elem_style {
  margin-right: 0.5rem;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
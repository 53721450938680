<template>
  <div>
    <div :class="[showHelp ? 'is-active' : '', 'modal']" v-if="(['Arrange', '24', 'Solvex'].includes(gameType))">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{gameType}} Demo</p>
          <button class="delete" aria-label="close" v-on:click="showHelp=false"></button>
        </header>
        <section class="modal-card-body has-text-left" v-if="gameType=='Solvex'">
          <p v-if="!isRace">
            {{gameType}} challenges you with problems appropriate for your rating. 
            <br>
            The problems may start easy. 
            <br>
            Then your rating will rise quickly, and the problems will increase in difficulty.
            <br>
            If the problems become too hard, your rating will drop, and problems will get easier.
          </p>
          <p v-else>
            {{gameType}} (Race) challenges you with problems that increase in difficulty. 
            <br>
            Solve as many as you can in 5min.
          </p>
        </section>
        <section class="modal-card-body" v-else>
          <figure class="image"> 
            <img :src="getDemoImg()">
          </figure>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>

    <div :class="[showRatingHelp ? 'is-active' : '', 'modal']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Rating explanation</p>
          <button class="delete" aria-label="close" v-on:click="showRatingHelp=false"></button>
        </header>

        <section class="modal-card-body">
          <p> 
            Mathleet uses an <a href="https://en.wikipedia.org/wiki/Elo_rating_system" target="blank">ELO</a> rating system.
          </p>
          <p v-if="isSelf()"> 
            Your rating increases more when you solve harder problems in solo mode. 
          </p>
          <p v-else>
            In multiplayer mode, your rating increases when you outperform your opponent.
          </p>
        </section>

        <footer class="modal-card-foot"></footer>
      </div>
    </div>

    <div class="columns " id="game">
        <!-- <transition name="fade" tag="p"> --> 
        <div id="gameTransition" class="column is-three-fifths" v-if="curProblem">

          <a href="javascript:void(0)" class="has-tooltip-arrow is-pulled-right" data-tooltip="Click for game demo" v-on:click="showHelp=true" v-if="(['Arrange', '24', 'Solvex'].includes(gameType))">
            <i class="fas fa-question-circle"></i>
          </a>

          <div class="columns " id="gameStatus" style="margin-bottom:-0.75rem">
            <div class="column has-text-link" id="myScore"> 
              <p class="has-text-weight-bold"> 
                {{me.handle}}
              </p> 
              {{myScore}}
            </div>
            <div v-bind:class="['column', 'has-text-dark', {'is-three-fifths':isSelf()}, {'has-text-left':isSelf()} ]" id="problemNumber"> 
              <p class="has-text-weight-bold"> 
                Problem {{curProblemIndex +1 }} 
              </p>  
              Score
              <div style="margin-top:0.5rem;">
                <div class="clock" v-if="!isRace" style="display:none">
                  <span v-text="hours"></span>:<span v-text="minutes"></span>:<span v-text="seconds"></span>
                </div>
                <aylTimer class="columns" :end-time="new Date().getTime() + 60000*RACE_TIME_MIN" ref="raceTimer" v-on:finish="endRace" v-else>
                  <template v-slot:process="{ timeObj }">
                    <span has-tooltip-multiline has-tooltip-arrow :data-tooltip="instructRace">{{ `Countdown: ${timeObj.m}:${timeObj.s}` }}</span>
                  </template>
                </ayltimer>
              </div>
            </div>
            <div class="column has-text-danger" id="oppScore" v-if="!isSelf()"> 
              <p class="has-text-weight-bold"> 
                {{opponent.handle }} 
              </p> 
              {{oppScore}}
            </div>
          </div>
          <div id="problemDisplay">
            <div class="columns " id="problemFormulation">
              <p class="column is-offset-one-quarter has-text-left">
                <label class="label">
                  <p class="has-text-info">
                    <span>{{instructShort}}:</span>
                  </p>
                  <p id="problemTextString" v-if="!(['Arrange', '24'].includes(gameType))" style="overflow-wrap: break-word; white-space: pre-line;" >
                    {{curProblem.text}}
                  </p>
                  <p v-if="gameType=='24'" class="has-text-info has-text-weight-light is-size-7" v-html="instructTip">{{instructTip}}</p>
                </label>
              </p>
            </div> <!-- problemFormulation -->
            
            <div v-if="gameType=='24'" >
                <draggable24 v-bind:numbers="getNumbers24" v-bind:operators="operators24" v-bind:gameType="gameType" v-on:draggableExpr="updateDraggableExpr" ref="drag_input" :key="curProblemIndex"> 
                </draggable24>
            </div>
            <div v-if="gameType=='Arrange'" >
                <draggable24 v-bind:numbers="getNumbers" v-bind:operators="getOperators"  v-bind:gameType="gameType" v-on:draggableExpr="updateDraggableExpr" ref="drag_input" :key="curProblemIndex"> 
                </draggable24>
            </div>
                
            <div v-if="gameType==='Solvex' && curProblem.problem_type==='CHOICES' " class="control" style="margin-top:-1rem" id="problem_choices">
              <span class="column is-half is-offset-one-quarter" style="padding:0.25rem" v-for="(img) in curProblem.img" :key="img">
                <img v-bind:src="'https://battleschool.s3.amazonaws.com/static/img/problem_figs/' + img" style="max-width:500px">
              </span>
              <span class="column is-half is-offset-one-quarter has-text-left" style="padding:0.25rem" v-for="(options, index) in curProblem.choices" :key="index">
                <label class="radio" style="margin-top: -10px">                      
                    <input type="radio" v-bind:value="options[0]" v-model="solution">
                    <span class="has-text-weight-semibold"> 
                     {{options[0]}})
                    </span>
                    <span v-bind:id="'problem_choice'+index">
                      {{options[1]}} 
                    </span>
                 </label>
              </span>
            </div>

            <div id="problemResponse" style="height:inherit">
              <aylTimer class="columns" :end-time="lockoutTime" ref="countdown_input" v-on:finish="focus_input">                
                <template v-slot:process="lockout">
                    <p class="control column is-half is-offset-one-quarter" style="padding-right:0rem">
                      <input class="input" type="text" v-model="solution" disabled v-bind:class="[{'is-invisible':curProblem.choices}]"> 
                    </p>
                    <p class="control" style="padding: 0.75rem;">
                      <button :class="[{'is-danger': submitLock=='Passed'}, {'is-info': submitLock=='Submitted'}, 'button']" :disabled="true" v-if="submitLock">
                        {{submitLock}}
                      </button>
                      <button class="button is-info" :disabled="true" v-else>
                        {{ `Locked: ${lockout.timeObj.ceil.s}` }}
                      </button>
                    </p>
                </template>
                <template v-slot:finish v-on:finishedQueue="handleAnswer">
                  <p class="control column is-5 is-offset-one-quarter" style="padding-right:0rem" v-bind:class="[{'is-invisible':curProblem.choices}]" >
                    <input class="input" type="text" :placeholder="computedExpression" v-model="solution" v-on:input="keyboardEval"  v-on:keyup.enter="startAnswerEnter" ref="text_input" autofocus :disabled="gameType=='Arrange'">
                  </p>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control" style="padding: 0.75rem 0rem 0.75rem 0rem;">
                      <button class="button is-info" v-on:click="startAnswer">Submit</button>
                    </p>
                    <p class="control" style="padding: 0.75rem 0rem 0.75rem 0rem;">
                      <button class="button is-danger has-tooltip-multiline has-tooltip-arrow" v-on:click="passProblem" data-tooltip="Move on to the next problem if both you and your opponent pass" v-if="!isSelf()">Pass</button>
                      <button class="button is-danger has-tooltip-multiline has-tooltip-arrow" v-on:click="passProblem" data-tooltip="Pass and go to the next problem" v-else>Pass</button>
                    </p>
                  </div>
                </template>
              </aylTimer>
            </div>  <!-- problemResponse -->
          </div> <!-- problemDisplay -->

          <div id="cardsDiv" v-if="!isSelf()">
            <!-- mycards -->
            <h1 class="title is-5" style="margin-bottom:0px; margin-left:8.333334%; text-align:left">
              Your cards
            </h1>
            <div class="tile is-ancestor">  
              <div class="tile is-parent is-flex-wrap-wrap">
                <div class="tile is-child is-1"/>
                <div class="tile is-child is-2" v-for="(c, index) in mycards" :key="index">
                  <Card :cardid="c.cardid" :showthumb="false" :index="index" :fuse="true" v-on:useCard="useCard" v-on:finishedCard="finishedCard" :ref="'card-'+index" />
                </div> 
              </div>
            </div>
          
            <h1 class="title is-5" style="margin-bottom:0px; margin-left:8.333334%; text-align:left"> 
              Opponent's cards
            </h1>
            <div class="tile is-ancestor">  
              <div class="tile is-parent is-flex-wrap-wrap">
                <div class="tile is-child is-1"/>
                <div class="tile is-child is-2" v-for="(c, index) in oppcards_display" :key="index">
                  <Card :cardid="c.cardid" :showthumb="false" :index="index" :fuse="true" v-on:finishedCard="finishedCard({'index':index, 'cardid':c.cardid}, true)" :ref="'oppcard-'+index" :opponents="true"/>
                </div> 
              </div>
            </div>
          </div> <!-- cardsDiv --> 
        </div>  <!--gameTransition -->
      <!-- </transition> -->      

      <div id="gameStats" class="column is-three-fifths" v-if="gameFinished">
        <span class="has-tooltip-arrow has-text-danger" data-tooltip="Players need to be registered to play rated games." v-if="!ratedGame" >
          Game was unrated.
        </span>

        <p class="has-text-weight-semibold" style="padding-bottom:1rem" v-if="!isSelf()"> 
          <span v-if="gameFinished==1">
            Winner is 
            <span class="has-text-black" v-bind:class="[{'has-text-link':gameWinner.handle===me.handle}, {'has-text-danger':gameWinner.handle!=me.handle}]">
              {{gameWinner.handle}} 
            </span>
          </span>          
          <span v-else>
            Game is a tie
          </span>
        </p>

        <div class="columns " id="gameStatsTable">
          <table class="table is-striped column is-9 is-offset-2">
            <thead>
              <tr>
                <th style="width:250px"></th>
                <th class="has-text-link" style="width:150px">{{me.handle}}</th>
                <th class="has-text-danger" style="width:150px" v-if="!isSelf()">{{opponent.handle}}</th>
              </tr> 
            </thead>
            <tbody>
              <tr>
                <td>
                  Rating                 
                  <a href="javascript:void(0)" class="has-tooltip-arrow" data-tooltip="Rating explanation" v-on:click="showRatingHelp=true">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </td>

                <td>
                  <span class="has-text-dark has-tooltip-arrow" data-tooltip="Your rating before game">
                    {{me.elo_old}}
                  </span>
                  <span class="icon is-small">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                  <span class="has-text-black" v-bind:class="['has-tooltip-arrow', {'has-text-success':me.elo>me.elo_old}, {'has-text-danger':me.elo<me.elo_old}]" data-tooltip="Your rating after game" v-if="ratedGame">
                    {{me.elo}} 
                  </span>
                  <span class="has-text-black" v-bind:class="['has-tooltip-arrow', {'has-text-success':me.elo>me.elo_old}, {'has-text-danger':me.elo<me.elo_old}]" data-tooltip="Your rating had game been rated" v-else>
                    <s> {{me.elo}} </s>
                  </span>
                </td>
                <td v-if="!isSelf()">
                  <span class="has-text-dark has-tooltip-arrow" data-tooltip="Opponent rating before game">
                    {{opponent.elo_old}}
                  </span>
                  <span class="icon is-small">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                  <span class="has-text-black" v-bind:class="['has-tooltip-arrow', {'has-text-success':opponent.elo>opponent.elo_old}, {'has-text-danger':opponent.elo<opponent.elo_old}]" data-tooltip="Opponent rating after game" v-if="ratedGame">
                    {{opponent.elo}} 
                  </span>
                  <span class="has-text-black" v-bind:class="['has-tooltip-arrow', {'has-text-success':opponent.elo>opponent.elo_old}, {'has-text-danger':opponent.elo<opponent.elo_old}]" data-tooltip="Opponent rating had game been rated" v-else>
                    <s> {{opponent.elo}} </s>
                  </span>
                </td>
              </tr>
              <tr>
                <td># Correct </td>
                <td>{{me.correct}} </td>
                <td v-if="!isSelf()">{{opponent.correct}} </td>
              </tr>
              <tr>
                <td># Wrong </td>
                <td>{{me.incorrect}} </td>
                <td v-if="!isSelf()">{{opponent.incorrect}} </td>
              </tr>
              <!--
              <tr>
                <td>Avg Time Correct (sec) </td>
                <td>{{ me.time_correct_avg }} </td>
                <td v-if="!isSelf()">{{ opponent.time_correct_avg }} </td>
              </tr>
              -->
            </tbody>
          </table>
        </div>  <!-- gameStatsTable -->
                       
        <div>
          <span class="has-text-weight-bold">Score</span>          
          <div v-on:click="shareScoreChart" id="shareScoreDiv" class="is-pulled-right has-tooltip-multiline has-tooltip-arrow" data-tooltip="Copy and share with friends">
            <button class="button is-small is-info" id="shareScoreButton">
              Copy & Share
            </button>
            <div id="sharingScoreButton" style="display:none"> 
              <label class="is-size-7">Copying</label>
              <progress class="progress is-small is-info" max="100">60%</progress>
            </div>
            <button class="button is-small is-success" id="sharedScoreButton" style="display:none">
              Copied
            </button>
          </div>

          <div class="scoreChart" id="scoreChart">         
            <span class="has-text-weight-bold" v-if="this.isRace"> {{this.gameType}} (Race)
            </span>
            <span class="has-text-weight-bold" v-else> {{this.gameType}}
            </span>
            <vn-line :model="scoreProgression" :toolTip="scoreTip" x-format=",f" y-format=",f" :height="'300'" x-axis-label="Time (sec)" y-axis-label="Number of Problems Solved" :y-min-max="[-(maxWrong+1), (maxCorrect+1)]" :x-min-max="[0, (this.gameEndTime - this.gameStartTime)/1000 + 10]" :colors="['#3273dc', '#f14668']">
            </vn-line>
          </div>
        </div>

        <hr>
        <div class="columns " id="gameEndButtons">
          <div id="playAgain" class="column is-one-quarter is-offset-one-quarter" v-if="isSelf()"> 
            <button class="button is-large is-link" v-bind:class="['button']" v-on:click="challenge">
              <span v-bind:data-tooltip="'Play again'">Play again</span>
            </button>
          </div>  <!-- playAgain --> 
          <div id="rematch" class="column is-one-quarter is-offset-one-quarter" v-else> 
            <button class="button is-large is-link" v-bind:class="[{'is-warning':me.await}, {'is-danger':rematch_issued}, 'button']" v-on:click="challenge">
              <span v-if="!rematch_issued" v-bind:data-tooltip="'Challenge ' +opponent.handle +' to a rematch'">Rematch</span>
              <span v-if="rematch_issued" v-bind:data-tooltip="'Accept rematch from ' +rematch_issuer">Accept rematch</span>
            </button>
          </div>  <!-- rematch -->

          <div class="column is-half">
            <router-link class='button is-large is-success' data-tooltip="Go back to Lobby" v-if="!$store.state.ingame" to="/lobby">
            <span v-on:click="msgLobby"> Lobby </span>
            </router-link>
            
            <button class="button is-large is-primary" data-tooltip="Register/login to play rated games, unlock calibrated problems and track your progress" v-if="is_anon_user(me.email)" @click="login">Register</button>
          </div>
        </div>

        <hr>
        <div id="problemStats" class="card">
          <header class="card-header">
            <p class="card-header-title">
              Problem Solutions
            </p>
            <a href="#collapsible-card" data-action="collapse" class="card-header-icon is-hidden-fullscreen" aria-label="Problem Solutions">
              <span class="icon">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </a>
          </header>
          <div id="collapsible-card" class="is-collapsible" style="overflow:hidden;">
            <div v-for="(prob, index) in problems" v-bind:key="index">
              <div v-if="index <= curProblemIndex">
                <br v-if="index==0"> 
                <div class="columns has-text-weight-bold" > 
                  <div class="column is-2"> 
                    Problem {{index +1}} 
                  </div>
                  <div class="column is-offset-2 is-narrow">
                    Rating: {{prob.elo.toFixed(0) }}
                  </div>
                  <div class="column is-offset-1 is-4">
                    Solved by: 
                    <span v-bind:class="[{'has-text-warning': (prob.solver=='Passed' && prob.solver!=opponent.handle)}, {'has-text-danger': (prob.solver==opponent.handle && me.handle!=opponent.handle) }, {'has-text-link':prob.solver==me.handle} ]">
                      {{prob.solver}} 
                    </span>
                  </div>
                </div>
                <div v-html="prob.text" style="overflow-wrap: break-word;" v-if="gameType!='Arrange'">
                  {{prob.text}} 
                </div>
                <div style="overflow-wrap: break-word;" v-else>
                  {{ prob.questionStr }} 
                </div>
                <div class="columns has-text-info has-text-weight-bold">
                  <p class="column is-2" style="padding:0.5rem">
                    Solution:
                  </p>
                </div>
                <div v-html="prob.solution_text" style="overflow-wrap: break-word; white-space: pre-line;" v-if="gameType!='Arrange'">                
                  {{prob.solution_text}}
                </div>
                <div style="overflow-wrap: break-word; white-space: pre-line;" v-else>
                  <span v-html="prob.text">
                    {{prob.text}} 
                  </span>
                  <span>
                    {{'$ =' + prob.answer + '$'}}
                  </span>
                </div>                
                <span class="column is-half is-offset-one-quarter" style="padding:0.25rem" v-for="(img) in prob.solution_img" :key="img">
                    <img v-bind:src="'https://battleschool.s3.amazonaws.com/static/img/problem_figs/' + img" style="max-width:500px">
                </span>
                <hr>
              </div> <!-- end if curProbleIndex -->
            </div> <!-- end problem loop -->          
          </div> <!-- end collapsible-card  -->
        </div> <!-- end problemStats -->        
    
      </div> <!-- gameStats div -->           

      <div class="column is-two-fifths" id="gameChat">
        <section class="hero ">
        <div class="hero-head">
          <header class="hero is-link is-bold">
            <span v-if="!isSelf()"> 
              Chat
            </span>
            <span v-else> 
              Log
            </span>
          </header>
        </div>
        <div class="chat hero-body" style="background:#777;height:350px;overflow-y:auto;" v-chat-scroll="{always:true, smooth:true, notSmoothOnInit:true, smoothonremoved:false}">
          <p v-bind:style="{padding: '.25em', textAlign: n.pos, overflowWrap: 'break-word'}" v-for="(n, index) in messages" v-bind:key="index">
          <span v-bind:class="['tag', n.style, 'mb-2', 'is-size-7']" style="display:inline-table;white-space:normal"> {{ n.m }} </span></p>
        </div>
        <div class="hero-foot">
          <form @submit="sendMsg">
          <div class="field has-addons">
            <div class="control is-expanded"><input class="input" name="userInput" type="text" placeholder="Type your message" /></div>
            <div class="control"> <button class="button is-info"> Send </button> </div>
          </div>
          </form>
        </div>
        </section>
      </div> <!-- end chat -->

    </div> <!-- columns div -->
  </div> <!-- main div -->
</template>

<script src="https://unpkg.com/vue@latest"></script>
<script src="https://unpkg.com/vue-awesome-countdown@latest"></script>
<script>
import {gsap, Linear, TimelineMax } from "gsap/all";
import { SECOND, HOUR, getHourTime, getZeroPad } from '../assets/js/Filters';

// don't forget to register plugins
gsap.registerPlugin(Linear, TimelineMax); 

import Card from '@/components/Card.vue'
import aylTimer from '@/components/aylcount.vue'
import draggable24 from '@/components/draggable_24.vue'
import bulmaCollapsible from '@creativebulma/bulma-collapsible/dist/js/bulma-collapsible.min.js'
import html2canvas from 'html2canvas'

// @ is an alias to /src
let WRONG_TIMEOUT = 3;
let SUBMIT_TIMEOUT = 0.5;
const KEY_CORRECT = 1;
const KEY_INCORRECT = -1;
const KEY_PASSED = 0;
const KEY_UNFINISHED = -2;
//const RACE_TIME_MIN = 5;

export default {
  name: 'PlayGame',
  components: {
    Card, aylTimer, draggable24
  },
  data: function () {
    return  { 
      instructShort:"", instructTip:"", instructRace:"Game ends when time runs out or 2 mistakes are made.", showHelp:false,showRatingHelp:false,
      gameProgress:[], gameWinner:null, gameFinished:0, myScore:0, oppScore:0, messages:[], ratedGame:true,
      curProblem:null, curProblemIndex:0, solution:'', notTypeset:true, notCreatedCollapsibleProbDetails: true,
      lockoutTime:null, passStatus: [0, 0], submitLock: false,
      mycards:[], oppcards:[], cardQueue:[], oppCardQueue:[], cardQueuePassive:[], oppCardQueuePassive:[], eventTriggered:false, 
      rematch_issued:false, rematch_issuer:null, rematched:false,
      computedExpression:"answer", numbers:[],
      hours: 0, minutes: 0, seconds: 0, hourtime: '', heartbeat: null,
      operators24: ["+", "-", "&times;", "&divide;", "^", "(", ")"],
      operators: [], gameOptions: {},
      isRace: false, startELO:1000, RACE_TIME_MIN:5, raceFinished:0,
      gameStartTime:0, gameEndTime:0, maxCorrect:0, maxWrong:0
    }
  },
  beforeDestroy() {
    console.log("PLAYGAME before destroy");  // msg lambda to in destroy()
    window.removeEventListener("beforeunload", this.preventNav);
    window.removeEventListener("unload", this.forceEndGame);
  },
  beforeRouteEnter: function(to, from, next) {
    console.log("PLAYGAME beforeRouteEnter!!!");
    if (document.store && document.store.state && document.store.state.ingame) {
      next();
    } else {    
      console.log("re-routing / to /lobby");
      alert("Game state was inadvertently lost. Re-routing to lobby.");

      next(
          { name: 'lobby', query: { from: from.path } }
      );
    }
  },
  beforeRouteLeave: function(to, from, next) {
    console.log("PLAYGAME beforeRouteLeave!!!");

    if (this.$store.state.ingame) {
      const answer = window.confirm('Do you really want to leave in the middle of a game? You will forfeit!');
      if (answer) {
        console.log("PLAYGAME beforeRouteLeave - leaving");
        window.removeEventListener("beforeunload", this.preventNav);
        window.removeEventListener("unload", this.forceEndGame);
        this.forceEndGame();
        next();
      } else {
        return;
      }
    } else {  // not in game
      console.log("PLAYGAME beforeRouteLeave (not in game) - leaving");
      window.removeEventListener("beforeunload", this.preventNav);
      window.removeEventListener("unload", this.forceEndGame);
      next();
    }
  },
  created: function() { 
    console.log("PLAYGAME created:" + new Date());

    window.addEventListener("beforeunload", this.preventNav); 
    window.addEventListener("unload", this.forceEndGame); 
    
    this.heartbeat = setInterval(this.beat, 10*60*1000);
    
    this.getGameStates();   // need to set problem to start render
    // this.initGame();     // repeated in activated!
    
    this.$socket.onmessage =  this.renderGame;
  },
  activated: function() { // if already played a game -> lobby -> new game
    console.log("PLAYGAME activated:" + new Date());
    this.$socket.onmessage =  this.renderGame;
    this.addMessage( {"type":"status", "m":"Game started!"} );    

    this.getGameStates();
    this.initGame();
  },
  methods: {  
    beat() {
      console.log("game heartbeat; " + this.me.cid + new Date());
      this.$socket.sendObj({"method": "heartbeat", "payload":{"msg":"game heartbeat", "p1":this.me.cid, "p2":this.opponent.cid}}); // TODO: same hack as lobby
    },
    playSound () {
      var audio = new Audio('https://mathleet.s3-us-west-2.amazonaws.com/audio/im.wav');
      audio.play();
    },    
    preventNav(event) {
      console.log('in preventNav');
      event.preventDefault();
      event.returnValue = "";
    },
    forceEndGame() {
      console.log('in forceEndGame');
      this.curProblem = null; // reset for new game

      if (this.$store.state.ingame) { // prevents duplicate logging of gameEnd and eloUpdate
        //alert('forceEndGame');

        this.$socket.sendObj({"method": "forceEndGame", "payload":{"p1":this.me.cid, "p2":this.opponent.cid, "p1e":this.me.email, "p2e":this.opponent.email, "curProblemIndex":this.curProblemIndex, "gameProgress":this.gameProgress, "gameType":this.gameType, "isRace":this.isRace, "startELO":this.startELO }});
        this.$store.commit("setInGame", false);
      }
    },
    endRace() {
      let curTime = new Date()
      console.log('in endRace (triggered by aylTime raceTimer)' +"; " +curTime);
      this.raceFinished = 1;
      
      this.$socket.sendObj({"method": "endRace", "payload":{"p1":this.me.cid, "p2":this.opponent.cid, "p1e":this.me.email, "p2e":this.opponent.email, "curProblemIndex":this.curProblemIndex, "gameProgress":this.gameProgress, "gameType":this.gameType, "isRace":this.isRace, "startELO":this.startELO }});
    },
    msgLobby: function() {
      const msg = this.me.handle + " has left the game.";
      this.addMessage({"type":"me", "m":msg});
      this.$socket.sendObj({method:"sendChat", payload:{cid:this.opponent.cid, msg: msg}});
    },
    sendMsg: function(e) {
      e.preventDefault();
      const msg = e.target.elements.userInput.value;
      this.addMessage({"type":"me", "m":msg});
      e.target.reset();
      if (!this.isSelf()) { // no point chatting to self
        this.$socket.sendObj({method:"sendChat", payload:{cid:this.opponent.cid, msg: msg}});
      }
    },
    addMessage: function(msgJSON) {
      msgJSON.style = "is-warning";
      msgJSON.pos = "center";
      if (msgJSON.type == "me") {
        msgJSON.style = "is-info";
        msgJSON.pos = "right";
      } else if (msgJSON.type == "you") {
        msgJSON.style = "is-success";
        msgJSON.pos = "left";
      }
      this.messages.push(msgJSON);
    },
    renderGame: function (m) {
      m = JSON.parse(m.data);
      breakme:
      if (m.m == "play") {        
        //console.log("PLAYING GAME! + new problem");
        //console.log(m.p);

        if (this.raceFinished) {
          console.log("in play: breakme triggered by raceFinished");
          this.curProblem = null;
          break breakme;
        }

        if (m.p.problems.length>0) {
          this.problems = this.problems.concat(m.p.problems);
          this.startELO = m.p.startELO;
          console.log('new problems received; ' +'startELO=' +this.startELO);
        }
        this.updateChatLog(m.p);

        if (this.curProblemIndex != m.p.curProblemIndex) { // only once
          if ("passStatus" in m.p) {
            if (!this.isSelf()) {
              this.addMessage( { "type":"status", "m":"You both passed on the problem" } );
            } else {
              this.addMessage( { "type":"status", "m":"You passed on the problem" } );
            }
          }
          this.curProblem = false;  // for transition effects
          this.addMessage( { "type":"status", "m":"Problem: " + (m.p.curProblemIndex+1) } );
          this.resetNewProblem(m);

        } else if ("passStatus" in m.p) {
          const numGP = m.p.gameProgress.length;
          let passMsg = "";
          if (m.p.gameProgress[numGP-1].cid==this.me.cid) {
            passMsg = "You passed on the problem";
          } else {
            passMsg = "Your opponent passed on the problem";
            this.passStatus[1] = 1;
          }
          this.addMessage( { "type":"status", "m":passMsg } );
        }
      
        this.gameProgress = m.p.gameProgress; // updated from server message
      } else if (m.m == "endGame") {
        console.log("END GAME!");
        this.gameEndTime = m.d.gameEndTime;

        this.updateChatLogEnd(m.d);
        this.addMessage( {"type":"status", "m":"Game ended!"} );
        //console.log(m.d);
        this.curProblem = null;  // hide game
        
        this.unlockInput();  // reset any locks from previous game
        this.processGameResults(m.d);
      } else if (m.m == "forcedEndGame") {
        this.addMessage({"type":"status", "m":"Your opponent left the game."});
        this.addMessage({"type":"status", "m":"You win by default."});
      } else if (m.m == "gotChat") {
        this.addMessage({"type":"you", "m":m.d});
      } else if (m.m == "playedCard") {
        var cardid = m.d.cardid,
            pCid = m.player,
            cardindex = m.d.cardindex;
        
        console.log('in renderGame and m.m="playedCard;"' +cardid +"; " +cardindex +"; " +pCid);
        this.handleCardAction(pCid, cardid, cardindex);
      } else if (m.m == "triggeredPassiveCard") {
        var pCid = m.player,
            cardid = m.d.cardid;
        let firstCard = this.getFirstEventCard(this.cardQueue);
        var cardindex_real = firstCard[0];
        this.runCardUI(cardindex_real, cardid);
        this.addMessage( {"type":"status", "m":"You sneakily ambushed your opponent with the " + cardid +" card."} );
      } else if (m.m == "challenge") {
        console.log(m);
        this.rematch_issued = true;
        this.rematch_issuer = m.challenger;
      } else if (m.m == "start") {  // this is for rematch!
        console.log("START GAME! - REMATCH");
        console.log(m.d);
        
        this.setGameStates(m); 
        this.getGameStates();
        this.initGame();  // init for game variables
      }
    },
    getGameStates: function() {
      //console.log("in getGameStates start; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );

      this.me = this.$store.state.gameState.me;
      this.opponent = this.$store.state.gameState.opponent;
      this.gameType = this.$store.state.gameState.gameType;
      this.gameOptions = this.$store.state.gameState.gameOptions;
      this.isRace = this.$store.state.gameState.isRace;
      this.startELO = this.$store.state.gameState.startELO;
      this.problems = this.$store.state.gameState.problems;
      this.curProblemIndex = this.$store.state.gameState.curProblemIndex;
      this.operators = this.$store.state.gameState.gameOptions.operators;
      this.gameStartTime = this.$store.state.gameState.gameStartTime;
      this.gameProgress = this.$store.state.gameState.gameProgress;
      
      //console.log("in getGameStates end; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );
    },
    setGameStates: function(m) {
      //console.log("in setGameStates start; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );

      this.me.cards = m.u[0].cid == this.me.cid ? JSON.parse(m.u[0].cards):JSON.parse(m.u[1].cards);
      const state = {
        me: this.me,
        opponent: {
          cid: m.u[0].cid != this.me.cid ? m.u[0].cid:m.u[1].cid,
          email: m.u[0].cid != this.me.cid ? m.u[0].email:m.u[1].email,
          handle: m.u[0].cid != this.me.cid ? m.u[0].handle:m.u[1].handle,
          cards: m.u[0].cid != this.me.cid ? JSON.parse(m.u[0].cards):JSON.parse(m.u[1].cards)
        },
        problems: m.p.problems,
        curProblemIndex: m.p.curProblemIndex,
        gameType: m.d.gameType,
        status: "start",
        gameOptions: m.d.gameOptions,
        isRace: m.d.isRace,
        startELO: m.d.startELO,
        gameStartTime: m.t,
        gameProgress: m.gp
      }
      this.$store.commit('setGame', state);
      
      //console.log("in setGameStates end; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );
    },
    focus_input: function(){
      this.$nextTick(() => {
        if (this.$refs.text_input) {
          this.$refs.text_input.focus();  // autofocus on input
        }
      });
    },
    finishedQueue: function(){
      console.log("in finishedQueue");
    },
    startAnswerEnter: function() {
      if (this.lockoutTime > new Date().getTime()) {
        console.log('startAnswerEnter; lockoutTime=' +this.lockoutTime)
        return -1;
      } else {
        return this.startAnswer();
      }
    },
    passProblem: function(evt) {
      console.log("passProblem: " + this.curProblemIndex);

      this.lockoutTime = new Date().getTime() + SUBMIT_TIMEOUT*1000;  
      this.lockInput("Passed");

      const i = this.curProblemIndex;
      this.passStatus[0] = 1;

      this.update_gameProgress(KEY_PASSED);
      this.$socket.sendObj({"method": "playGame", "payload":{"p1":this.me.cid, "p2":this.opponent.cid, "p1e":this.me.email, "p2e":this.opponent.email, "curProblemIndex":i, "gameProgress":this.gameProgress, "passStatus":this.passStatus, "gameType":this.gameType, "isRace":this.isRace, "startELO":this.startELO }});
    },
    startAnswer: function(evt) {
      this.lockoutTime = new Date().getTime() + SUBMIT_TIMEOUT*1000;  
      this.lockInput("Submitted");

      this.checkQueueForEventCards(); // check if ambush
    },
    handleAnswer: function() {
      console.log("in handleAnswer (start); after card queues" )

      const i = this.curProblemIndex;
      let isCorrect = false,
          isSolved = KEY_INCORRECT;
      
      // test input validity
      let isValidStr = false,
          gameTypeMsg ="",
          regex = null;
      if (['Arithmetic'].includes(this.gameType)) {
        regex = /^[0-9\s\-]+$/;
        isValidStr = regex.test(this.solution);
        gameTypeMsg = !isValidStr ? "Invalid answer: please enter a number":"";
      } else if (this.gameType=='24') {
        // can also do crazy RegExp from https://stackoverflow.com/questions/24891868/regex-to-match-permuted-set-of-characters/24891932
        // ^(?=.{0,5}a)(?=.{0,5}b)(?=.{0,5}c)(?=.{0,5}d)(?=.{0,5}e)(?=.{0,5}f).*
        regex = /[+\-*/^x()\s]/gi;
        let stripped_input = this.solution.replace(regex, "");
        let stripped_input_sorted = stripped_input.split("").sort().join("");
        let problem_sorted = this.curProblem.text.replace(/[$,]/g, "").split("").sort().join("");
        isValidStr = stripped_input_sorted.localeCompare(problem_sorted)==0? 1:0;  // 0 is equivalent in localeCompare
        gameTypeMsg = !isValidStr ? "Invalid answer: you can only use every number once, along with mathematical operations":"";
      } else if (this.gameType=='Solvex') {
        isValidStr = true;
      } else if (this.gameType=='Arrange') {
        isValidStr = true;
      }

      if (isValidStr) {
        if (this.gameType=='Arrange') {
          let compExp = this.solution;
          let exprToks = compExp.split('=');
          let left_side = exprToks[0],
              right_side = exprToks[1];
          try {
            isCorrect = eval(left_side)==eval(right_side);
          } catch (e) {
            isCorrect = false;
          }
        } else {
          isCorrect = checkAnswer(this.curProblem, this.solution);
        }
      } else {
        this.addMessage( {"type":"status", "m":gameTypeMsg} );
      }

      if (isCorrect) {
        console.log('correct answer; i=' + i +'; ' + new Date());
        const correct_sol_msg1 = 'Your opponent answered correctly: ' + this.solution,
              correct_sol_msg2 = 'You answered correctly: ' + this.solution;;
        this.addMessage({m:correct_sol_msg2, type:"me"});
        if (!this.isSelf()) {
          this.$socket.sendObj({method:"sendChat", payload:{cid:this.opponent.cid, msg:correct_sol_msg1, color:"is-warning"}});   // show solution to other player
        }
        isSolved = KEY_CORRECT;
        this.curProblem.isDone = true;
        this.solution = '';  // reset;
      } else {
        console.log('incorrect answer; i=' +i +'; ' + new Date());
        isSolved = KEY_INCORRECT;
        this.addMessage( {"type":"status", "m":"Your solution is incorrect. Try again"} );
        this.focus_input();
        
        /*
        this.addMessage( {"type":"status", "m":"Incorrect, locked for " +WRONG_TIMEOUT +" seconds"} );
        this.lockoutTime = new Date().getTime() + WRONG_TIMEOUT*1000;
        console.log(this.lockoutTime);
        this.lockInput();
        */
      }
      
      this.update_gameProgress(isSolved);
      this.$socket.sendObj({"method": "playGame", "payload":{"p1":this.me.cid, "p2":this.opponent.cid, "p1e":this.me.email, "p2e":this.opponent.email, "curProblemIndex":i, "gameProgress":this.gameProgress, "gameType":this.gameType, "isRace":this.isRace, "startELO":this.startELO }});
    },
    update_gameProgress(gpStatus){
      let curGP_index = this.gameProgress.length-1; // last one      
      this.gameProgress[curGP_index]["cid"] = this.me.cid;
      this.gameProgress[curGP_index]["elo"] = this.curProblem.elo;
      this.gameProgress[curGP_index]["problem_id"] = this.curProblem.problem_id;
      this.gameProgress[curGP_index]["isSolved"] = gpStatus;
    },
    processGameResults: function(gameResults) {
      //console.log("in processGameResults start; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );

      this.gameProgress = gameResults.gameProgress; // update on last action
      if (gameResults.winner_cid === null) { // tie
        this.gameFinished = -1;
      } else {
        this.gameFinished = 1;
        this.gameWinner = gameResults.winner_cid == this.me.cid ? this.me : this.opponent;  
      }
      
      const myResults = gameResults.userScores[this.me.cid];
      this.me.elo = myResults.elo;
      this.me.elo_old = myResults.elo_old;
      this.me.correct = myResults["correct"].length;
      this.me.time_correct = myResults["time_correct"].reduce((a,b) => a+b, 0) /1000;
      this.me.time_correct_avg = this.me.correct ? (this.me.time_correct/this.me.correct).toFixed(1)  : "-";
      this.me.incorrect = myResults["incorrect"].length;
      this.me.time_incorrect = myResults["time_incorrect"].reduce((a,b) => a+b, 0) /1000;
      this.me.time_incorrect_avg = this.me.incorrect ? (this.me.time_incorrect/this.me.incorrect).toFixed(1)  : "-";
      this.me.passed = myResults["passed"].length;
      
      const oppResults = gameResults.userScores[this.opponent.cid];
      this.opponent.elo = oppResults.elo;
      this.opponent.elo_old = oppResults.elo_old;
      this.opponent.correct = oppResults["correct"].length;
      this.opponent.time_correct = (oppResults["time_correct"].reduce((a,b) => a+b, 0) ) /1000;
      this.opponent.time_correct_avg = this.opponent.correct ? (this.opponent.time_correct/this.opponent.correct).toFixed(1)  : "-";
      this.opponent.incorrect = oppResults["incorrect"].length;
      this.opponent.time_incorrect = (oppResults["time_incorrect"].reduce((a,b) => a+b, 0))/1000;
      this.opponent.time_incorrect_avg = this.opponent.incorrect ? (this.opponent.time_incorrect/this.opponent.incorrect).toFixed(1)  : "-";
      this.opponent.passed = oppResults["passed"].length;

      // game aliases for easy access
      if (this.isRace) {
        this.gameEndTime = this.gameEndTime!=0 ? this.gameEndTime: (Date.now()) ;  // else already set in this.endRace()
      } else {  // if no problem done
        this.gameEndTime = this.gameProgress.length >0 ? this.gameProgress[this.gameProgress.length-1].solveTime : (Date.now());
      }
      this.maxCorrect = Math.max(this.me.correct, this.opponent.correct);
      this.maxWrong = Math.max(this.me.incorrect, this.opponent.incorrect);

      this.$store.commit("setInGame", false); // change ingame state!

      //console.log("in processGameResults end; users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );
    },
    updateChatLog: function(p) {
      const gameProgress = p.gameProgress,
          numGP = gameProgress.length,
          lastGP = gameProgress[numGP-1];      

      if (lastGP.isSolved==KEY_PASSED) {
        this.problems[this.curProblemIndex].solver = "Passed";
      }

      if (lastGP.cid==this.me.cid) {
        this.solution = '';  // reset for new problem or wrong input

        if (lastGP.isSolved==KEY_CORRECT) {
          this.myScore += 1;
          this.problems[this.curProblemIndex].solver = this.me.handle;
        } else if (lastGP.isSolved==KEY_INCORRECT) {
          this.addMessage( {"type":"status", "m":"You lose a point"} ); // duplicate message
          /*
          if (this.curProblem.choices) {
            this.myScore -= 1;
            this.addMessage( {"type":"status", "m":"You lose a point"} ); // duplicate message
          } else {
            1
          }
          */
        }
      } else {
        if (lastGP.isSolved==KEY_CORRECT) {
          this.oppScore += 1;
          this.problems[this.curProblemIndex].solver = this.opponent.handle;
        } else if (lastGP.isSolved==KEY_INCORRECT) {
          if (this.curProblem.choices) {
            this.oppScore -= 1;
            this.addMessage( {"type":"status", "m":"The other player got it wrong, and lost a point."} );
          } else {
            this.addMessage( {"type":"status", "m":"The other player got it wrong"} );
          }
        }
      }
    },
    updateChatLogEnd: function(d) { // visual display only
      const myCorrects = d.userScores[this.me.cid].correct,
          oppCorrects = d.userScores[this.opponent.cid].correct;

      // needed for last problem; otherwise handled in updateChatLog
      if (myCorrects.indexOf(this.curProblemIndex) > -1) {
        this.addMessage( {"type":"status", "m":"You got it right"} );
        this.problems[this.curProblemIndex].solver = this.me.handle;
      } else if (oppCorrects.indexOf(this.curProblemIndex) > -1) {
        this.addMessage( {"type":"status", "m":"The other player got it right"} );
        this.problems[this.curProblemIndex].solver = this.opponent.handle;
      }
    },
    useCard: function(d) {
      const cardindex = "card-"+d.index;
      console.log("in useCard: " +d.cardid +"; " +cardindex +"; " +this.me.cid +"; " +this.opponent.cid);
      this.$socket.sendObj({method:"playCard", payload:{p1:this.me.cid, p2:this.opponent.cid, cardid:d.cardid, cardindex:cardindex }} );
      
      //this.manageCardUIQueue(cardindex, d.cardid);  // trigger UI immediately
    },
    manageCardUIQueue: function(cardindex, cardid) {
      console.log("in manageCardUIQueue;" +cardid +"; " +cardindex );

      const targetCardQueue = this.whichQueue(cardindex, cardid);
      const isPassive = this.isPassiveCard(cardid);
      if (cardid==='blank') { // might be better to trigger blank image for oppcards that are unused!
        return;
      }
      let cardStatus = isPassive ? "Activated":"Queued";
      this.$refs[cardindex][0].setStatus(cardStatus);
      targetCardQueue.push([cardindex, cardid]);
    },
    isPassiveCard: function(cardid) {
      const cardInfo = this.$store.state.carddb[cardid];
      return cardInfo.isPassive;
    },
    getFirstEventCard: function(q=this.oppCardQueue) {
      let firstEventCard = null;
      for (let i=0; i<q.length; i++) {
        const curCard = q[i],
              cardindex = curCard[0],
              cardid = curCard[1];
        if (this.isPassiveCard(cardid)) {
          firstEventCard = curCard;
          break;
        } 
      }
      return firstEventCard;
    },
    checkQueueForEventCards: function() {
      console.log("in checkQueueForEventCards");
      let firstEventCard = this.getFirstEventCard(this.oppCardQueue);
      if (firstEventCard) {
        const d = {"index":firstEventCard[0], "cardid":firstEventCard[1]};
        this.eventTriggered = true;
        this.triggerPassiveCard(d);
      } else {
        this.handleAnswer();
      }
    },
    whichQueue: function(cardindex, cardid) {
      console.log("in whichQueue;" +cardid +"; " +cardindex);

      const cardInfo = this.$store.state.carddb[cardid],
            cardAction = cardInfo.action,
            cardStrength = parseInt(cardInfo.strength);

      if (cardAction=="shield") {
        const cardindex_rev = cardindex.includes("opp") ? cardindex.replace("opp", "") : "opp"+cardindex;
        return this.whichQueueHelper(cardindex_rev);
      } else {
        return this.whichQueueHelper(cardindex);
      }
    },
    whichQueueHelper: function(cardindex) {
      if (cardindex.includes("opp")) {
        return this.oppCardQueue;
      } else {
        return this.cardQueue;
      }
    },
    finishedCard: function(d, opponents=false) {
      const cardindex = opponents? "oppcard-"+d.index:"card-"+d.index;
      const targetCardQueue = this.whichQueue(cardindex, d.cardid);
      console.log("Card action finished: " + d.cardid + " index: " + cardindex);      
      
      if (["24", "Arrange"].includes(this.gameType)) {
        this.$refs.drag_input.editable = true;
      }

      targetCardQueue.shift();  // pop card now active - should trigger watch on queue
      if (this.eventTriggered) {
        if (targetCardQueue==this.oppCardQueue) {
          let firstCard = this.oppCardQueue.length>0 ? this.oppCardQueue[0]:null // any more cards
          if (firstCard===null) {
            this.handleAnswer();
            this.eventTriggered = false; // event over + revert to initial state
          } else {          
            const d = {"index":firstCard[0], "cardid":firstCard[1]};
            const isPassive = this.isPassiveCard(d.cardid);
            if (isPassive) {
              this.triggerPassiveCard(d);
            } else {
              console.log("should just run");
            }
          }
        }
      }

      /*
      const isPassive = this.isPassiveCard(d.cardid);
      if (isPassive) {
        if (targetCardQueue==this.oppCardQueue) {
          let firstEventCard = this.getFirstEventCard(this.oppCardQueue);  // any more event cards
          if (firstEventCard===null) {
            this.handleAnswer();
          } else {
            const d = {"index":firstEventCard[0], "cardid":firstEventCard[1]};
            this.triggerPassiveCard(d);
          }
        }
      }
      */
    },
    runCardUI: function(cardindex, cardid) {
      const cardInfo = this.$store.state.carddb[cardid],
            cardAction = cardInfo.action;
      let cardStrength = parseInt(cardInfo.strength);
      cardStrength = cardStrength ? cardStrength:2;  // default 2 sec progress bar

      this.$refs[cardindex][0].setStatus(null);
      this.$refs[cardindex][0].runTimer(cardStrength);
      console.log("in runCardUI: "+ cardid +" index: "+ cardindex +" strength:"+ cardStrength +"; " +this.$refs[cardindex][0].fullTime +"; " +this.$refs[cardindex][0].$refs.timerp.actualEndTime +"; " + new Date().getTime());
      // setTimeout(() => { this.$set(this.mycards[cardindex], "finished", true ) }, 2000) // how to finish a card
    },
    stopCard: function(cardindex, cardid) {
      console.log("stopCard: " + cardid + " index: " +cardindex);
      this.$refs[cardindex][0].stopTimer();
    },
    handleCardAction: function(cid, cardid, cardindex) {   // cardEngine
      // handle opponent card UI      
      console.log('in handleCardAction: ' +cid +'; ' +this.me.cid +'; ' +cardid +'; ' +cardindex );
      
      if (cid != this.me.cid) {
        const cardindex_num = cardindex.replace('card-', '');
        //const cardindex_new = cardindex.replace('card-', 'oppcard-');
        const cardindex_new = 'oppcard-' + this.oppcards.length;
        this.oppcards.push({cardid: cardid, finished:false, cardindex:cardindex_new });
        // this.$refs[cardindex_new][0].setIsHidden(false);
        
        // move v-on:mounted="triggerMountedOppCard" since blank placeholder cards
        console.log("in handleCardAction: " + cardindex_new +"; " +this.oppcards.length);
        this.manageCardUIQueue(cardindex_new, cardid);
      } else {
        this.manageCardUIQueue(cardindex, cardid);  // trigger UI and action for mycards
      }
    },
    handleCardFunctionality: function(cid, cardid, cardindex) {
      console.log('in handleCardFunctionality: ' +cid +'; ' +cardid +'; ' +cardindex);
      const cardInfo = this.$store.state.carddb[cardid],
            cardAction = cardInfo.action,
            cardStrength = cardInfo.strength;

      let cardMsg = '',
          subj_obj = cid==this.me.cid ? ["You", "your opponent"]: ["Your opponent", "you"];

      switch(cardAction) {
        case "freeze":
          cardMsg = subj_obj[0] +' froze ' +subj_obj[1] +' for ' +cardStrength +' seconds';
          if (cid!=this.me.cid) { // opponent froze you
            const cur_time = new Date().getTime();
            this.lockoutTime = this.lockoutTime ===null ? cur_time : Math.max(this.lockoutTime, cur_time);
            this.lockoutTime += parseFloat(cardStrength)*1000;
            this.lockInput();
            if (["24", "Arrange"].includes(this.gameType)) {
              this.$refs.drag_input.editable = false;
            }
          }
          break;
        case "ambush":
          cardMsg = subj_obj[0] +' sneakily ambushed ' +subj_obj[1] +' for ' +cardStrength +' seconds';
          if (cid!=this.me.cid) { // opponent froze you
            const cur_time = new Date().getTime();
            this.lockoutTime = this.lockoutTime ===null ? cur_time : Math.max(this.lockoutTime, cur_time);
            this.lockoutTime += parseFloat(cardStrength)*1000;
            this.lockInput();
            if (["24", "Arrange"].includes(this.gameType)) {
              this.$refs.drag_input.editable = false;
            }
          }
          break;
        case "reduce":        
          subj_obj = cid==this.me.cid ? ["You", "your opponent's"]: ["Your opponent", "your"];
          cardMsg = subj_obj[0] +' reduced ' +subj_obj[1] +" attack by " +cardStrength +' seconds';
    
          if (cid==this.me.cid) { // self-defense - update timer on self and oppCards
            var attackCardInfo = this.oppCardQueue[0];
          } else {  // opp-defense - update timer on self and myCards
            var attackCardInfo = this.cardQueue[0];
          }
          const defCard = this.$refs[cardindex][0];          
          const cardTime = parseFloat(cardStrength),
                curTime = new Date().getTime();
          const endTime_def = defCard.$refs.timerp.actualEndTime,
                remTime_def = (endTime_def - curTime); 
          
          let cardindex_att = null,
              cardid_att = null,
              attCard = null;
          let endTime_att = curTime,
              remTime_att = 0;
          if (typeof attackCardInfo ==='undefined') {
            console.log("wasted reduce card");
          } else {
            cardindex_att = attackCardInfo[0];
            cardid_att = attackCardInfo[1];
            attCard = this.$refs[cardindex_att][0];
            endTime_att = attCard.$refs.timerp.actualEndTime;
            remTime_att = (endTime_att - curTime);
          }
          
          // N.B. ms to seconds for runTimer
          // N.B. timeRemaining_def doesnt carry over
          const timeRemaining_def = Math.max( cardTime*1000 - remTime_def, 100)/1000,
                timeRemaining_att = Math.max( remTime_att - cardTime*1000, 100)/1000;
          defCard.runTimer(timeRemaining_def);
          if (attCard!==null) {
            attCard.runTimer(timeRemaining_att);
          }

          console.log('in handleCardFunctionality: ' +cardAction +'; ' +curTime +'; ' +endTime_def +'; ' +remTime_def +'; ' +timeRemaining_def*1000 +'; ' +endTime_att +'; ' +remTime_att +'; ' +timeRemaining_att*1000 +'; ' +cardid +'; ' +cardindex +'; ' +cardid_att +'; ' +cardindex_att);

          if (cid==this.me.cid) { // cardStrength on reduce card!
            const endTime = this.lockoutTime, 
                  endTime_new = endTime - cardTime*1000;
            this.lockoutTime = endTime_new;
            this.lockInput();
          }
          break;
        case "nullify":
          cardMsg = subj_obj[0] +' nullified all attacks';
          if (cid==this.me.cid) {
            this.unlockInput();
            this.resetCardQueue(this.oppCardQueue); 
          } else {
            this.resetCardQueue(this.cardQueue); 
          }
          break;
        case "shield":
          // added to oppCardQueue to prevent oppcards hitting -> subj obj role reversal
          if (subj_obj[1]=="you") {
            cardMsg = 'You are shielded from attacks for ' +cardStrength +' seconds';
          } else {
            cardMsg = 'Your opponent is shielded from attacks for ' +cardStrength +' seconds';
          }
          break;
        default:
          cardMsg = subj_obj[0] +' ' +cardAction +'ed ' +subj_obj[1] +' for ' +cardStrength +' seconds';
          console.log('in handleCardFunctionality; default case ');
          break;
      }
      this.addMessage( {"type":"status", "m":cardMsg} );
    },
    lockInput: function(submitLock=false) {
      const lockComponent = this.$refs["countdown_input"];
      lockComponent.setEndTime(this.lockoutTime);
      lockComponent.startCountdown(true);

      this.submitLock = submitLock;
    },
    unlockInput: function() {    
      const lockComponent = this.$refs["countdown_input"];
      if (typeof lockComponent !=='undefined') {
        this.lockoutTime = null;
        lockComponent.finishCountdown();
      }
    },
    resetNewProblem: function(m) {
      this.curProblemIndex = m.p.curProblemIndex;
      this.curProblem = this.problems[this.curProblemIndex];
      this.curProblem.timeShown = m.p.shownTime;
      this.gameProgress = m.p.gameProgress;
      this.solution = '';  // reset for new problem
      this.passStatus = [0, 0];

      console.log("resetNewProblem - resetting cardQueue");
      this.unlockInput();
      this.resetCardQueue(this.cardQueue);
      this.resetCardQueue(this.oppCardQueue);
      this.focus_input();

      this.typesetProblem();
    },
    resetCardQueue: function(targetCardQueue) {
      let temp = targetCardQueue.slice();  // copy
      targetCardQueue= []; // reset to avoid finishedCard problems
      for (let i=0; i<temp.length; i++) {
        let firstCardInfo = temp[i];
        let cardindex = firstCardInfo[0],
            cardid = firstCardInfo[1];
        //this.$refs[cardindex][0].setStatus(false);
        //this.stopCard(cardindex, cardid);
        this.$refs[cardindex][0].runTimer(0.0001);  // super fast to use up cards and avoid finishedCard
        console.log("in resetCardQueue; card: " + cardid + " index: " + cardindex);
      }
    },
    resetGame: function() {
      this.me.await = false;

      this.gameWinner = null; // reset gameWinner
      this.myScore = 0;
      this.oppScore = 0;
      this.messages = [];
      this.mycards = [];
      this.oppcards = [];
      this.cardQueue = [];
      this.oppCardQueue = [];
      this.cardQueuePassive = [];
      this.oppCardQueuePassive = [];

      this.rematch_issued = false;
      this.rematch_issuer = null;
      this.rematched = false;
      this.notTypeset = true;
      this.notCreatedCollapsibleProbDetails = true;
      this.gameFinished = 0;
      this.raceFinished = 0;
      this.computedExpression = ""; // reset for placeholders 
      this.solution = ""; // reset so NO default choice

      this.ratedGame = !this.is_anon_user(this.me.email) && !this.is_anon_user(this.opponent.email);
      console.log('in resetGame: ' + new Date() +'; notTypeset= ' +this.notTypeset +'compExpr= ' +this.computedExpression);
    },
    initGame: function() {
      clean_tooltips();
      this.resetGame();    // reset game variables for match

      console.log("problems: " + new Date() + JSON.stringify(this.problems)); // debug Ming problems
      //console.log("users: " + new Date() +";" +JSON.stringify(this.me) +";" +JSON.stringify(this.opponent) );

      this.curProblem = this.problems[this.curProblemIndex];
      this.curProblem.timeShown = this.gameStartTime;
      this.gameEndTime = 0;

      this.addMessage( { "type":"status", "m":"Problem: " + (this.curProblemIndex + 1) } ); // 1-index display
      
      // init cards
      this.me.cards.forEach((d) => {
        this.mycards.push({cardid: d, finished:false})
      });
      /*
      this.opponent.cards.forEach((d) => {
        this.oppcards.push({cardid: d, finished:false})
      });
      */

      switch (this.gameType) {
        case "24":
          this.instructShort = "Drag-n-drop or type an expression that gives 24";
          //this.instructTip = "Use each number once along with +, -, &times;, &divide;, ^ and ()";
          this.instructTip = "Use each number once along with +, -, *, /, ^ and ().";
          break;
        case "Arithmetic":
          this.instructShort = "Calculate";
          this.instructTip = "Calculate and enter the answer";
          break;
        case "Solvex":
          this.instructShort = "Solve";
          this.instructTip = "Solve";
          break;
        case "Arrange":          
          this.instructShort = "Drag-n-drop the numbers and operations correctly";
          this.instructTip = "Drag-n-drop the numbers and operations for a valid expression";
          break;
        default:
          this.instructShort = "Calculate";
          this.instructTip = "Calculate and enter the answer";
          break;
      }
      console.log('in initGame: ' + new Date() + this.instructShort +"; " +this.instructTip);
    },
    triggerMountedOppCard: function(d) {
      console.log("in triggerMountedOppCard: " + d.cardid);
      const cardindex = "oppcard-"+d.index;
      this.manageCardUIQueue(cardindex, d.cardid);
    },
    triggerPassiveCard: function(d) {
      console.log("triggerPassiveCard; " +d.index +"; " +d.cardid);
      // notify opponent
      this.$socket.sendObj({method:"triggerPassiveCard", payload:{p1:this.me.cid, p2:this.opponent.cid, cardid:d.cardid, cardindex:d.index }} );
      // trigger UI and functionality for user
      this.runCardUI(d.index, d.cardid);
      this.handleCardFunctionality(this.opponent.cid, d.cardid, d.index);
    },
    challenge: function() {
      console.log("in challenge: " + new Date())
      if (this.rematch_issued || this.isSelf()) {
        if (!this.rematched) {  // prevent double submit
          this.rematched = true; 
          this.$socket.sendObj( {"method":"startGame", "payload":{"p1":this.me.cid, "p2":this.opponent.cid, "p1e":this.me.email, "p2e":this.opponent.email, "gameType":this.gameType, "gameOptions":this.gameOptions, "isRace":this.isRace, "startELO":this.startELO}} );
        }
      } else {
        this.addMessage({"type":"me", "m":"You challenged " +this.opponent.handle +" to a rematch"});
        const msg = this.me.handle + " has challenged you to a rematch";
        this.$socket.sendObj({method:"sendChat", payload:{cid:this.opponent.cid, msg: msg}});

        this.me.await = true;
        this.$socket.sendObj({"method":"challenge", "payload":{"other":this.opponent.cid, "challenger":this.me.handle, "gameType":this.gameType, "gameOptions":this.gameOptions, "isRace":this.isRace, "startELO":this.startELO}});
      }
    },
    keyboardEval: function() {    
      let valStr = '';
      if (this.gameType!='24') {
        return;
      } else {
        try {
          valStr = eval(this.solution.replace(/\^/g, '**'));
        } catch (e) {
          valStr = this.solution;
        }
        //this.$refs.valCheck = valStr;
        // console.log('in keyboardEval' +' ' +this.gameType +' ' +this.solution +' ' +this.computedExpression +' ' +valStr);
        document.getElementById('valCheck').value = valStr;
      } 
    },
    updateDraggableExpr: function(o) {
      this.computedExpression = o["computedExpression"];
      this.solution = this.computedExpression;
    },
    updateProblemTime() {
      const now = new Date();
      if (this.curProblem!== null) {
        const temp = timeDiff(now.valueOf(), this.curProblem.timeShown);
        this.hours = getZeroPad(temp[0]),
        this.minutes = getZeroPad(temp[1]),
        this.seconds = getZeroPad(temp[2].toFixed(0));
        this.$options.timer = window.setTimeout(this.updateProblemTime, SECOND);
      }
    },
    isSelf: function() {
      return this.me.email == this.opponent.email;
    },
    typesetProblem: function() {
      console.log('in typesetProblem; before mathjax');
      this.playSound(); // sound notification on new problem      

      const tl = new TimelineMax();
      
      tl.to("#problemNumber", 0.10, {scale:0.90, rotation:-8});
      tl.to("#problemNumber", 0.15, {scale:1.2, rotation:0, ease:Linear.easeNone}, "+=0.1");
      tl.to("#problemNumber", 0.75, {rotation:3, ease:Linear.easeNone});
      tl.to("#problemNumber", 0.15, {scale:1, rotation:0});

      tl.fromTo("#problemDisplay", {opacity:0.3 }, {opacity: 1 , duration: 1 });
      
      //const node_base = document.getElementById("problemDisplay");
      //window._MathJax.typesetClear([node]); // to forget old MathJax for alignment and over format (not needed here)
      const node = document.getElementById("problemTextString");
      if (node && !this.raceFinished) {
        node.innerHTML = this.curProblem.text;
        window._MathJax.typesetPromise([node]).then(() => {
          console.log("typeset new mathjax content; " + this.curProblem.text);  // the new content has been typeset
        });
        //window._MathJax.typeset();  //synchronous call
        // window._MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, "problemTextString"]);  // MathJax2 style
      }

      if (this.gameType=="Solvex" ) {  // only multiple choice questions
        let num_choices = this.curProblem.choices.length;
        if (this.curProblemIndex> 0) {
          const prevProblem = this.problems[this.curProblemIndex-1];
          num_choices = Math.max(num_choices, prevProblem.choices.length);
        }

        for (let j=0; j<num_choices; j++) {
          let choiceNode = document.querySelector("#problem_choice"+j);
          let choiceText = this.curProblem.choices[j] ? this.curProblem.choices[j][1]:"";
          choiceNode.innerHTML = choiceText;
          window._MathJax.typesetPromise([choiceNode]).then(() => {
            console.log("typeset new mathjax content; " + choiceText);  // the new content has been typeset
          });
        }
      }
    },
    shareScoreChart: function(e) {
      e.preventDefault();
      document.getElementById("shareScoreButton").style.display = "none";
      document.getElementById("sharingScoreButton").style.display = "inherit";
      document.getElementById("sharedScoreButton").style.display = "none";

      // get the node
      var domNode = document.getElementById('scoreChart');
      domNode.classList.add("on");

      // copy the canvas to the clipboard with chrome's CliboardItem API
      // https://developers.google.com/web/updates/2019/07/image-support-for-async-clipboard#images
      html2canvas(domNode).then(function(canvas) {
        console.log('starting copy');

        canvas.toBlob(function(blob) {
          console.log('inside canvas.toBlob:' + blob);

          navigator.clipboard.write([
            new window.ClipboardItem({
              [blob.type]: blob
            })
          ])
            .then(function() {
            console.log("Copied to clipboard");
            document.getElementById("shareScoreButton").style.display = "none";
            document.getElementById("sharingScoreButton").style.display = "none";
            document.getElementById("sharedScoreButton").style.display = "inherit";
            domNode.classList.remove("on");
          });
        });
      });
    },
    getDemoImg: function() {
      var urlStr = "https://mathleet.s3-us-west-2.amazonaws.com/tutorial/" +this.gameType.toLowerCase() +".gif";
      //var urlStr = "https://mathleet.s3-us-west-2.amazonaws.com/tutorial/" +this.gameType.toLowerCase() +".webp"; // not supported by all browsers
      //var urlStr = "https://mathleet.s3-us-west-2.amazonaws.com/tutorial/" +this.gameType.toLowerCase() +".png"; // highest res - but 5mb; not supported by all browsers
      return urlStr; 
    },
    is_anon_user: function(email) {
      return email.includes("mathleet.com")
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    
    scoreTip(d) {
      const d_data0 = d.series[0].data,
            d_key0  = d.series[0].key,
            d_colour0 = d.series[0].color;      
      let d_data1 = d_data0,
          d_key1  = d_key0,
          d_colour1 = d_colour0;
      if (!this.isSelf()){
        d_data1 = d.series[1].data,
        d_key1  = d.series[1].key,
        d_colour1 = d.series[1].color;
      }
            
      let tipTable = document.createElement("table");
      let tipHead = document.createElement("thead"),
          headTR = document.createElement("tr"),
          headTD = document.createElement("td"),
          headAtt = document.createAttribute("colspan"),
          headSty = document.createAttribute("style");
      headTD.innerHTML = "<span class='has-text-weight-semibold'>Game Time: " + d3.format('.0f')(d_data0.x) + " sec</span>";
      headAtt.value="3";
      headTD.setAttributeNode(headAtt);
      headSty.value = "text-align:center";
      headTD.setAttributeNode(headSty);
      headTR.append(headTD);
      tipHead.append(headTR);
      tipTable.append(tipHead);
      
      let keyColorDiv0 = document.createElement("div"),
          keyColorSty0 = document.createAttribute("style");
      keyColorSty0.value="background-color: " + d_colour0;
      keyColorDiv0.setAttributeNode(keyColorSty0);
      let keyColorDiv1 = document.createElement("div"),
          keyColorSty1 = document.createAttribute("style");
      keyColorSty1.value="background-color: " + d_colour1;
      keyColorDiv1.setAttributeNode(keyColorSty1);

      let keyTR = document.createElement("tr"),
          keyTD0 = document.createElement("td"),
          keyTD1 = document.createElement("td"),
          keyTD2 = document.createElement("td"),
          keyAtt1 = document.createAttribute("class"),
          keyAtt2 = document.createAttribute("class"),
          keyTRAtt = document.createAttribute("rowspan");

      keyAtt1.value = "legend-color-guide";
      keyTD1.setAttributeNode(keyAtt1);
      keyTD1.innerHTML = "<span class='value'>" + d_key0 + "</span>"; 
      keyTD1.append(keyColorDiv0);
      
      keyAtt2.value = "legend-color-guide";
      keyTD2.setAttributeNode(keyAtt2);
      keyTD2.innerHTML = "<span class='value'>" + d_key1 + "</span>"; 
      keyTD2.append(keyColorDiv1);

      keyTRAtt.value="2";
      keyTR.setAttributeNode(keyTRAtt);
      keyTR.append(keyTD0);
      keyTR.append(keyTD1);
      if (!this.isSelf()) {
        keyTR.append(keyTD2);
      }

      let scoreTR = document.createElement("tr"),
          scoreTD0 = document.createElement("td"),
          scoreTD1 = document.createElement("td"),
          scoreTD2 = document.createElement("td"),
          scoreAtt1 = document.createAttribute("class"),
          scoreAtt2 = document.createAttribute("class");

      scoreTD0.innerHTML = 'Score:';
      scoreTD1.innerHTML = "<span class='value'>" + d_data0.y + "</span>";       
      scoreTD2.innerHTML = "<span class='value'>" + d_data1.y + "</span>"; 
      scoreTR.append(scoreTD0);
      scoreTR.append(scoreTD1);
      if (!this.isSelf()) {
        scoreTR.append(scoreTD2);
      }

      let actTR = document.createElement("tr"),
          actTD0 = document.createElement("td"),
          actTD1 = document.createElement("td"),
          actTD2 = document.createElement("td"),
          actAtt1 = document.createAttribute("class"),
          actAtt2 = document.createAttribute("class");
      actTD0.innerHTML = "Action:";

      let gpAction = 'N';
      switch(d_data0.act) {
        case 1:
          gpAction = 'Solved'
          break;
        case 0:
          gpAction = 'Passed'
          break;
        case -1:
          gpAction = 'Incorrect'
          break;
        default:
          gpAction = ''
          break;
      }
      if (d_data0.actor==this.me.cid) {
        actTD1.innerHTML = "<span class='has-text-weight-semibold'>" +gpAction + "</span>";
      } else if (d_data0.actor==this.opponent.cid) {
        actTD2.innerHTML = "<span class='has-text-weight-semibold'>" +gpAction + "</span>";
      }
      actTR.append(actTD0);
      actTR.append(actTD1);
      if (!this.isSelf()) {
        actTR.append(actTD2);
      }

      let tipBody = document.createElement("tbody");
      tipBody.append(keyTR);
      tipBody.append(scoreTR);
      tipBody.append(actTR);
      tipTable.append(tipBody);
      
      return tipTable.outerHTML;
    }
  },
  computed: {
    oppcards_display: function() {
      console.log("in oppcards_display: ");

      let blank_cards = [];
      for (let j=this.oppcards.length; j<5; j++) {
        let cur_card = {cardid: "blank", finished:false};
        blank_cards.push(cur_card);
      }
      return this.oppcards.concat(blank_cards);
    },
    getNumbers24: function() {
      console.log('in getNumbers24:' +new Date() +this.curProblem.text);
      const num = this.curProblem.text.replace(/[$]/g, "").split(",");
      return num;
    },
    getNumbers: function() {
      console.log('in getNumbers');
      let probText = this.curProblem.text;
      probText = probText.replace(/\\times/g, "");
      probText = probText.replace(/\\div/g, "");
      let num_toks = probText.replace(/[$+-/*///^()]/g,"").split(/\s/);
      let num = [];
      for (let j=0; j<num_toks.length; j++) {
        if (num_toks[j].length>0) {
          num.push(num_toks[j]);
        }
      }
      num.push("=");
      num.push(this.curProblem.answer);
      shuffle(num); // more interesting if shuffled, but harder
      console.log(num)
      this.curProblem['questionStr'] = num.join(" ");
      return num;
    },    
    getOperators: function() {
      console.log('in getOperators');
      let real_operators = [];
      let includeParen = false;
      for (let j=0; j<this.operators.length; j++) {
        let curOp = this.operators[j];
        let realOp = curOp;
        switch(curOp) {
          case "*":
            realOp = "&times;"
            includeParen = true;
            break;
          case "\\":
          case "/":
            realOp = "&divide;"
            includeParen = true;
            break;
           default:
            realOp = curOp;
            break;
        }
        real_operators.push(realOp);
      }
      if (includeParen) {
        real_operators.push("(");
        real_operators.push(")");
      }
      return real_operators ;
    },    
    scoreProgression: function() {
      let numSolved_me = [],
          cumSolved_me = 0,           
          numSolved_opp = [],
          cumSolved_opp = 0;
          
      // zero pad even if no problem solved (no gameProgress)
      let dict0 = {
        x: 0,
        y: 0,
        z: "Problem 1",
        probELO: this.problems[0].elo,
        actor: '',
        act: ''
      }
      numSolved_me.push(dict0);
      numSolved_opp.push(dict0);
      
      // log gameProgress
      for (let i=0; i<this.gameProgress.length; i++) {
        console.log(i +"; " +this.gameProgress[i].problemIndex +"; " +this.gameProgress[i].shownTime +"; " +this.gameProgress[i].solveTime +"; " +toSeconds(this.gameProgress[i].solveTime-this.gameProgress[i].shownTime) +"; " +toSeconds(this.gameProgress[i].solveTime-this.gameProgress[0].shownTime) +"; " +new Date(this.gameProgress[0].shownTime) +"; " +new Date(this.gameProgress[i].solveTime)); 
      }
      
      for (let i=0; i<this.gameProgress.length; i++) {
        let curProgress = this.gameProgress[i];
        let curTime = curProgress.solveTime - this.gameProgress[0].shownTime;

        if (curProgress.cid==this.me.cid) { // whose action
          cumSolved_me += curProgress.isSolved;
        } else {
          cumSolved_opp += curProgress.isSolved;
        }
        let curDict_me = {
          x: toSeconds(curTime),
          y: cumSolved_me,
          z: "Problem " + (curProgress.problemIndex+1),
          probELO: curProgress.elo,
          actor: curProgress.cid,
          act: curProgress.isSolved
        };
        let curDict_opp = {
          x: toSeconds(curTime),
          y: cumSolved_opp,
          z: "Problem " + (curProgress.problemIndex+1),
          probELO: curProgress.elo,
          actor: curProgress.cid,
          act: curProgress.isSolved
        };
        numSolved_me.push(curDict_me);
        numSolved_opp.push(curDict_opp);
      }      

      if (this.isSelf()) { 
        return [
          {
            key: this.me.handle,
            bar: true,
            values: numSolved_me
          }
        ];
      } else {
        return [
          {
            key: this.me.handle,
            bar: true,
            values: numSolved_me
          },
          {
            key: this.opponent.handle,
            bar: true,
            values: numSolved_opp
          },
        ];
      }
    },
  },
  mounted() {
    console.log('PLAYGAME mounted:' + new Date());
    this.$options.timer = window.setTimeout(this.updateProblemTime, SECOND);
    this.typesetProblem();    
  },
  updated: function() {
    //console.log('PLAYGAME updated ' +'pIndex=' +this.curProblemIndex +" typeset=" +this.notTypeset);
    const node = document.getElementById("problemStats");  // doesnt exist until rendered
    if (node) {
      window._MathJax.typesetPromise([node]).then(() => {
        console.log("typeset new mathjax content; " + "in updated");  // the new content has been typeset
      });
      
      // Access to the bulmaCollapsible instance from DOM
      const bulmaCollapsibleElement = document.getElementById('collapsible-card');
      if (bulmaCollapsibleElement && this.notCreatedCollapsibleProbDetails) {
        // Instanciate bulmaCollapsible component on the node
        new bulmaCollapsible(bulmaCollapsibleElement);

        // Call method directly on bulmaCollapsible instance registered on the node
        //bulmaCollapsibleElement.bulmaCollapsible('collapsed');  // overriden by is-active
        this.notCreatedCollapsibleProbDetails = false;
      }
    }
    if (this.curProblemIndex==0 && this.notTypeset) {  // for new game and rematch - document has to be mounted before typeset
      const node = document.getElementById("problemNumber");  // doesnt exist until rendered
      if (node) {
        this.typesetProblem();
        this.notTypeset = false;
      }
    }
  },
  watch: {
    myScore: function() {
      const tl = new TimelineMax();
      tl.to("#myScore", 0.25, {autoAlpha:0, repeat:3, yoyo:true, ease:Linear.easeNone}, "+=0.5");
    },
    oppScore: function() {
      const tl = new TimelineMax();
      tl.to("#oppScore", 0.25, {autoAlpha:0, repeat:3, yoyo:true, ease:Linear.easeNone}, "+=0.5");
    }, 
    cardQueue: function() {
      console.log('in watch cardQueue', this.cardQueue.length);
      if (this.cardQueue.length>0) {
        const firstCardInfo = this.cardQueue[0];  
        const cardindex = firstCardInfo[0],
              cardid = firstCardInfo[1];
        const cardActiveUI = this.$refs[cardindex][0].activeUI;
        if (!cardActiveUI && !this.isPassiveCard(cardid)) {  // not already run
          this.runCardUI(cardindex, cardid);
          this.handleCardFunctionality(this.me.cid, cardid, cardindex);
        }
      }
    },
    oppCardQueue: function() {
      console.log('in watch oppCardQueue', this.oppCardQueue.length);

      if (this.oppCardQueue.length>0) {
        const firstCardInfo = this.oppCardQueue[0];  
        const cardindex = firstCardInfo[0],
              cardid = firstCardInfo[1];
        const cardActiveUI = this.$refs[cardindex][0].activeUI;
        if (!cardActiveUI && !this.isPassiveCard(cardid)) {  // not already run
          this.runCardUI(cardindex, cardid);
          this.handleCardFunctionality(this.opponent.cid, cardid, cardindex);
        }
      }
    },
    /* - use passive queue for buffs
    cardQueuePassive: function() {
      console.log('in watch cardQueuePassive', this.cardQueuePassive.length);
    },
    oppCardQueuePassive: function() {
      console.log('in watch oppCardQueuePassive', this.oppCardQueuePassive.length);
    },
    */
  }
}

function preprocess(submittedString) {  // fixes exponents for js
  submittedString = submittedString.replace(/x/g, '*');
  return submittedString.replace(/\^/g, '**');
}
function checkAnswer(problem, submittedString) {
  console.log('in checkAnswer:' + new Date() + '; problem=' + problem.text + '; submitted=' + submittedString);
  if (problem.problem_type==="CHOICES") {
    return problem.solution.toUpperCase()===submittedString.toUpperCase();
  } else {
    try {
      return problem.answer==eval(preprocess(submittedString));
    } catch (e) {
      return false;
    }
  }
}
function timeDiff(date_future, date_now) {
  // get total seconds between the times
  var delta = Math.abs(date_future - date_now) / 1000;
  
  /*
  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;
  */

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60;  // in theory the modulus is not required

  return [hours, minutes, seconds];
}
/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
function toMinutes (d) {
  return d/1000/60; // ms to minutes
}
function toSeconds (d) {
  return d/1000; // ms to seconds
}
function clean_tooltips() {
  let  dom_tooltips = document.getElementsByClassName("nvtooltip");
  // console.log("in clean_tooltips: " + dom_tooltips.length)
  for (let i=0; i<dom_tooltips.length; i++) {
    // dom_tooltips[i].style.display = "none";
    dom_tooltips[i].remove();
  }
}
</script>

<!--
<style>
#app { display:block; }

@media only screen and (orientation:portrait){
  #app {
    height: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media only screen and (orientation:landscape){
  #app {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
  }
}
</style>

-->
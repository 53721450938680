<template>
  <div>
    <h1 class="title">My Deck</h1>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child is-2">
          <div class="m-2">
            <button class="mt-2 mb-2 button is-info" :disabled="saving || firstload || mycards.length < 5" v-on:click="saveDeck" :class="[saving ? 'is-loading' : '', 'mt-2', 'mb-2', 'button', 'is-info']"><span v-if="!firstload">Save deck</span><span v-if="firstload">Saved</span></button>
            <div class="control">
              <label class="radio">
                <input type="radio" name="libraryview" checked v-on:change="renderLib('only')"> My cards
              </label>
              <label class="radio">
                <input type="radio" name="libraryview" v-on:change="renderLib('all')"> All
              </label>
            </div>
<!--
          <div class="mt-6">
          <h3>{{coins}} e7 coins</h3>
          <button class="button is-primary" v-on:click="buycoins=true">Buy coins</button> <br/>
          <button class="button is-primary" v-on:click="buycards=true">Buy cards</button>
          </div>
-->
          </div>
        </div>
        <div class="tile is-child is-2" v-for="(c, index) in mycards" :key="index">
          <Card :cardid="c.cardid" :fremove="true" :index="index" v-on:removeCard="removeCard" />
        </div> <!--tile child-->
      </div>
    </div><!--tile ancestor-->
    <h1 class="title">Card Library</h1>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-flex-wrap-wrap">
        <div class="tile is-child is-2" v-for="(q, c, index) in cards" :key="index">
          <Card :cardid="c" :fhistory="true" :quant="q" :fquant="true" :ftake="true" v-on:takeCard="takeCard" />
        </div> 
      </div>
    </div>
<!-- REMOVE LATER -->
    <h1 class="title">Game view</h1>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-flex-wrap-wrap">
        <div class="tile is-child is-2" v-for="(c, index) in mycards" :key="index">
          <Card :cardid="c.cardid" :showthumb="false" :index="index" :fuse="true" v-on:useCard="useCard" v-on:finishedCard="finishedCard" :ref="'card-'+index" />
        </div> 
      </div>
    </div>
<!-- END REMOVE LATER -->
  </div>
</template>
<script>
import Card from '@/components/Card.vue'
export default {
  name: 'Profile',  
  components: {
    Card
  },
  data: function() {
    return { firstload : true, buycards:false, buycoins:false, coins: 100, newcards:[], saving: false, curlib:null,
             mycards : [], cards: {}, carddb:{}, cardorder:[] }
  },
  created: function() {
  },
  activated: function() {
    this.$socket.onmessage =  this.render
    this.$socket.sendObj({"method":"getDeck", "payload": {"email": this.$auth.user.email}})
    this.$socket.sendObj({"method":"getLibrary", "payload": {"email": this.$auth.user.email}})
  },
  methods: {
    useCard: function(d) {
      console.log("Card action to be triggered: " + d.cardid + " index: " + d.index)
      this.$refs["card-" + d.index][0].runTimer(20)
      
      //setTimeout(() => { this.$set(this.mycards[d.index], "finished", true ) }, 2000) // how to finish a card
    },
    finishedCard: function(d) {
      console.log("Card action finished: " + d.cardid + " index: " + d.index)
    },
    render: function (m) {
      m = JSON.parse(m.data)
      console.log(m.m)
      if (m.m == "gotDeck") {
        this.mycards = []
        JSON.parse(m.d).forEach((d) => {
          this.mycards.push({cardid: d, finished:false})
        })
      } else if (m.m == "gotLibrary") {
        this.curlib = JSON.parse(m.d)
        this.renderLib("only")
      } else if (m.m == "savedDeck") {
        this.saving = false
        this.firstload = true
      }
    },
    renderLib(view) {
      this.cards = {}
      this.$store.state.cardorder.forEach((c) => {
        if (view == "only" && c in this.curlib) {
          this.cards[c] = this.curlib[c]
        } else if (view == "all") {
          this.cards[c] = 0
        }
      })
      window._MathJax.typeset()
    },
    saveDeck() {
      this.saving = true
      var dbcards = []
      this.mycards.forEach((c) => {
        dbcards.push(c.cardid)
      })
      this.$socket.sendObj({"method":"saveDeck", "payload": {"email": this.$auth.user.email, "deck":JSON.stringify(dbcards)}})
    },
    removeCard(i) {
      this.firstload = false
      const c = this.mycards[i]
    
      if (c.cardid in this.cards) {
        this.$set(this.cards, c.cardid, this.cards[c.cardid] + 1)
      } else {
        this.cards[c.cardid] = 0;
        this.$set(this.cards, c.cardid, this.cards[c.cardid] + 1)
      }
      //this.cards[c] += 1
      this.$delete(this.mycards, i)
      return false
    },
    takeCard(c) {
      if (this.cards[c] > 0 &&  this.mycards.length < 5 ) {
        console.log(c)
        console.log(this.mycards)
        this.cards[c]--
        this.mycards.push({"cardid": c, "finished":false} )
      }
      return false
    },
    getImgUrl(pic) {
      return "https://mathleet.s3-us-west-2.amazonaws.com/card_images" + pic
      //return require("../assets/" + pic)
    },
    SpendtoBuyCards() {
      this.coins =- 100 // synce with server
      this.newcards = ["hero", "hero", "hero"]
      this.newcards.forEach( function(c) {
        if (this.cards[c] == null){
          this.cards[c] += 0
        }
        this.cards[c] += 1
      })
    }
  }
}
</script>
<style>
.card-content {
  padding:0.5rem

}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -120px;
}

.card {
  overflow: unset;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";
// Import the plugin here
import { Auth0Plugin } from "./auth";

//import Socket from '@/socket'
import VueChatScroll from 'vue-chat-scroll'
import VueNativeSock from 'vue-native-websocket'
import VueCountryCode from "vue-country-code"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNVD3 from './components/vue-nvd3'

// Plugins
Vue.use(VueCountryCode);
Vue.use(VueChatScroll)
Vue.use(VueAxios, axios)
Vue.use(VueNVD3)

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {      
    console.log(appState)

    //document.vue.$connect();  // not needed after socket connectManually removed
    const logincount = document.vue.$auth.user["http://mathleet.com/aylcount"];
    // to check loginstatus on F5 / refresh
    sessionStorage.setItem('mathleet', logincount);
    
    var redirectpath = "/lobby"
    if (logincount == 1) {
      console.log("NEW USER!")
      redirectpath = "/createUser"
    }
    
    router.push( redirectpath);
    /*
    if (redirectpath=='/lobby') {
        router.go();    // reloads page even if same url
    } else {
        router.push( redirectpath);
    }
    */
  }
});

Vue.use(Vuex)


export const store = new Vuex.Store({
  state: {
    count: 0,
    gameState: null,
    ingame: false,
    carddb: null,
    cardorder: null,
    socket: {
      isConnected: false,   // Connection Status
      message: "",          // Message content
      reconnectError: false,// Reconnect error 
      heartBeatInterval: 500000,// Heartbeat message sending time
      heartBeatTimer: 0      // Heartbeat timer
    }
  },
  mutations: {
    increment (state) {
      state.count++
    } ,
    setGame(state, payload) {
      state.gameState = payload // me, opponent, problems
      state.ingame = true
    },
    setInGame(state, value) {
      state.ingame = value
    },
    saveCarddb(state, value) {
      state.carddb = value.carddb
      state.cardorder = value.cardorder
    },
    // https://github.com/likaia/vue-native-websocket-vue3/blob/master/README-EN.md
    // Connection open
    SOCKET_ONOPEN(state, event) {
      //Vue.prototype.$socket = event.currentTarget;
      document.vue.$socket = event.currentTarget;
      state.socket.isConnected = true;     
      
      console.log("SOCKET CONNECTED: " + new Date());

      // When the connection is successful, start sending heartbeat messages regularly to avoid being disconnected by the server
      state.socket.heartBeatTimer = setInterval(() => {
        const message = "heartbeat";
        console.log(message +" " +new Date());
        state.socket.isConnected &&
          document.vue.$socket.sendObj({
            code: 200,
            msg: message,
            method: message,
            payload: {},
          });
      }, state.socket.heartBeatInterval);
    },
    gameHeartBeat(state, event) {
        document.vue.$socket = event.currentTarget;
        const message = "heartbeat";
        document.vue.$socket.sendObj({
            code: 200,
            msg: "game heartbeat",
            method: message,
            payload: {},
        });
    }, 
    // Connection closed
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false;
      // Stop the heartbeat message when the connection is closed
      clearInterval(state.socket.heartBeatTimer);
      state.socket.heartBeatTimer = 0;
      
      console.log("SOCKET CLOSED: " + new Date());
      
      console.log(event);
    },
    // An error occurred
    SOCKET_ONERROR(state, event) {        
      console.log("SOCKET ERROR: " + new Date());

      console.error(state, event);
    },
    // Receive the message sent by the server
    SOCKET_ONMESSAGE(state, message) {
      console.log("SOCKET MESSAGE: " + new Date());

      state.socket.message = message;
    },
    // Auto reconnect
    SOCKET_RECONNECT(state, count) {
      console.log("SOCKET RECONNECT: " + new Date());

      console.info("reconnecting", state, count);
    },
    // Reconnect error
    SOCKET_RECONNECT_ERROR(state) {
      console.log("SOCKET RECONNECT_ERROR: " + new Date());

      state.socket.reconnectError = true;
    }
  }
})

Vue.use(VueNativeSock, 'wss://94c0tq813j.execute-api.us-west-2.amazonaws.com/production', {
  format: 'json',
  //connectManually: true,  // this breaks sockets on page refresh
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  store: store
})

Vue.config.productionTip = false

export const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*
router.beforeEach((to, from, next) => {
    console.log('in router.beforeEach:'+to.path +";" +from.path);
    next();
});
// https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
// or use https://www.npmjs.com/package/vuex-persistedstate
router.beforeResolve((to, from, next) => {
    console.log('in router.beforeResolve');
    
    const curpath = window.location.pathname;
    //const logincount = "vue" in document && document.vue.$auth.user!== undefined ? 1:0;
    const logincount = sessionStorage.getItem("mathleet");
    console.log(logincount +' ' + curpath);

    if (logincount && to.path=="/") {
        console.log("re-routing / to /lobby");
        next(
            { name: 'lobby', query: { from: curpath } }
        );
    } else {
        next();
    }
});
*/

document.vue = vue; 
document.store = store; 
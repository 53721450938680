<template>
<div class="about">
  <div :class="[showRatingHelp ? 'is-active' : '', 'modal']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rating explanation</p>
        <button class="delete" aria-label="close" v-on:click="showRatingHelp=false"></button>
      </header>

      <section class="modal-card-body">
        <p> 
          Mathleet uses an <a href="https://en.wikipedia.org/wiki/Elo_rating_system" target="blank">ELO</a> rating system.
        </p>
        <p> 
          Your rating increases more when you solve harder problems in solo mode. 
        </p>
        <p>
          In multiplayer mode, your rating increases when you outperform your opponent.
        </p>
      </section>

      <footer class="modal-card-foot"></footer>
    </div>
  </div>

  <section class="hero">
    <div class="hero-body" style="padding-top:1rem">
      <div class="container has-text-centered">
        <h2 class="title has-tooltip-arrow has-tooltip-bottom" data-tooltip="Refreshes every 30min" >Leaderboards</h2>
      </div>
    </div>

    <div class="hero-foot">
      <nav class="tabs is-large is-toggle is-centered">
        <div class="container">
          <ul>
            <li class="tab is-active" @click="openTab('globalLeaderboard', $event)"><a class="has-tooltip-arrow has-tooltip-left" data-tooltip="Rating Leaderboard">Global</a></li>
            <li class="tab" @click="openTab('24Leaderboard', $event)"><a class="has-tooltip-arrow has-tooltip-right" data-tooltip="24 (Race) Monthly Leaderboard" style="min-width:120px">24 </a></li>
            <li class="tab" @click="openTab('SolvexLeaderboard', $event)"><a class="has-tooltip-arrow has-tooltip-right" data-tooltip="Solvex (Race) Monthly Leaderboard" >Solvex</a></li>
          </ul>
        </div>
      </nav>
     </div>
  </section>
  
  <div class="container section">
    <div id="globalLeaderboard" class="content-tab" >
      <div class="columns">
        <div class="column is-one-fourth">
        </div>
        <div class="column is-one-half">          
          <div class="columns is-pulled-right" style="margin-top:-2rem">
            <a href="javascript:void(0)" class="has-tooltip-arrow" data-tooltip="Rating explanation" v-on:click="showRatingHelp=true">
              <i class="fas fa-question-circle"></i>
            </a>
          </div>
          <br>

          <progress v-if="loading" class="progress is-large is-primary" max="100">60%</progress>
  
          <div v-if="!loading">
            <div class="columns" v-for="(u, index) in users" v-bind:key="index" v-bind:style= "[index%2==0 ? {'background-color':'#fafafa'} : {'background-color': '#FFF'}]" >
              <div class="column is-one-fifth" :data-tooltip="u.country">
                <img width="28" v-bind:src="'https://www.countryflagsapi.com/svg/' + u.country " /> 
              </div>
              <div class="column is-three-fifths">
                {{u.handle}}
              </div>
              <div class="column is-one-fifth">
                {{u.elo}}
              </div> 
            </div> <!-- end for -->
          </div> <!-- end if -->
        </div> <!-- end one-half -->
        <div class="column is-one-fourth"></div>
      </div> <!-- end columns -->
    </div> <!-- end globalLeaderboard -->
    
    <div id="24Leaderboard" class="content-tab" style="display:none">
      <div class="columns">
        <div class="column is-one-fourth"></div>
        <div class="column is-one-half">
          <progress v-if="loading_24" class="progress is-large is-primary" max="100">60%</progress>
          <div v-if="!loading_24">

            <div class="columns" v-for="(u, index) in users_24" v-bind:key="index" v-bind:style= "[index%2==0 ? {'background-color':'#fafafa'} : {'background-color': '#FFF'}]" >
              <div class="column is-4">
                {{u.handle}}
              </div>
              <div class="column is-1">
                {{u.t_correct}}
              </div>
              <div class="column is-7">
                {{ (new Date(u.startTime)).toLocaleString() }}
              </div>
            </div> <!-- end for -->
          
          </div> <!-- end if -->
        </div> <!-- end one-half -->

        <div class="column is-one-fourth"></div>
      </div> <!-- end columns -->
    </div> <!-- end 24Leaderboard -->

    <div id="SolvexLeaderboard" class="content-tab" style="display:none">
      <div class="columns">
        <div class="column is-one-fourth"></div>
        <div class="column is-one-half">
          <progress v-if="loading_solvex" class="progress is-large is-primary" max="100">60%</progress>
          <div v-if="!loading_solvex">

            <div class="columns" v-for="(u, index) in users_solvex" v-bind:key="index" v-bind:style= "[index%2==0 ? {'background-color':'#fafafa'} : {'background-color': '#FFF'}]" >
              <div class="column is-4">
                {{u.handle}}
              </div>
              <div class="column is-1">
                {{u.t_correct}}
              </div>
              <div class="column is-7">
                {{ (new Date(u.startTime)).toLocaleString() }}
              </div>
            </div> <!-- end for -->
          
          </div> <!-- end if -->
        </div> <!-- end one-half -->

        <div class="column is-one-fourth"></div>
      </div> <!-- end columns -->
    </div> <!-- end SolvexLeaderboard -->

  </div> <!-- end container -->
</div>
</template>

<script>
export default {
  name: 'LeaderBoard',
  data: function() { 
    return { 
      loading: true, 
      users:[],
      loading_24: true,
      users_24: [],
      loading_solvex: true,
      users_solvex: [],
      showRatingHelp: false
    } 
  },
  created: function() {
  }, 
  activated: function() {
    this.loading = true;
    this.axios.get("https://mathleet.s3-us-west-2.amazonaws.com/leaderboard.json").then((res) => {
      console.log(res)
      this.loading = false;
      this.users = res.data.Items;
    });
    
    this.axios.get("https://mathleet.s3-us-west-2.amazonaws.com/leaderboard_24.json").then((res) => {
      console.log(res)
      this.loading_24 = false;
      this.users_24 = res.data;
    });
    
    this.axios.get("https://mathleet.s3-us-west-2.amazonaws.com/leaderboard_Solvex.json").then((res) => {
      console.log(res)
      this.loading_solvex = false;
      this.users_solvex = res.data;
    });
  },  
  methods: { 
    openTab: function(tabName, evt) {
      var i, x, tablinks;
      x = document.getElementsByClassName("content-tab");
      for (i = 0; i < x.length; i++) {
          x[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tab");
      for (i = 0; i < x.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" is-active", "");
      }
      document.getElementById(tabName).style.display = "block";
      evt.currentTarget.className += " is-active";
    }
  }
}
</script>

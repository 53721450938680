<template>
  <div class="content has-text-left">
    <div class="columns">
      <div class="column is-offset-2 is-8">
        <h1 class="title has-text-info">Our Philosophy</h1>
        <div>
        <ul>
          <li>Learning is fun! </li>
          <li>Learn by playing games with friends</li>
          <li>Learning should be accessible - Mathleet is free!</li>
        </ul>
        </div>
        
        <h1 class="title has-text-info">Games</h1>
        <p>
        Mathleet games are fun because:
        <ul>
          <li> they require creative thinking </li>
          <li> they are calibrated for you. They are not too easy and boring or too hard and frustrating </li>
          <li> you can compete with friends in multi-player mode </li>
        </ul>
        </p>

        <h1 class="title has-text-info">Game Types</h1>
        <p><b>Arithmetic</b> hones your calculation skills </p>
        <p>
          <b>Arrange</b> sharpens your mathematical intuition and pattern recognition
          <br>
          <img src="https://mathleet.s3-us-west-2.amazonaws.com/tutorial/arrange.gif">
        </p>
        <p>
          <b>24</b> challenges you to find creative arrangements to make the number 24 
          <br>
          <img src="https://mathleet.s3-us-west-2.amazonaws.com/tutorial/24.gif">
        </p>
        <p>
          <b>Solvex</b> challenges you with advanced problems calibrated to your skill level
        </p>

        <h1 class="title has-text-info">Mathematician Cards </h1>
        <ul>
          <li>Cards give you special powers in multiplayer games</li>
          <li>Share the fun mathematician stories on the cards with your friends </li>
          <li>Earn cards by playing games</li>
        </ul>
        
        <h1 class="title has-text-info">Stats </h1>
        <ul>
          <li>Track your progress with <b>My Stats </b> </li>
          <li>Compare yourself against the rest of the world on the <b>Leaderboard </b> </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import welcome from '@/components/welcome2.vue'

export default {
  name: 'About',
}
</script>
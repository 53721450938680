<template>
  <transition name="modal">
    <div class="modal-break">
      <div class="modal-background-break">
        <div class="modal-card">

          <div class="modal-card-header">
            <slot name="header"> <!-- can use slots to customize modal -->
              <h3 class="has-text-weight-bold">Arrange Game Options</h3>
            </slot>
          </div>

          <div class="modal-card-body">
            <slot name="body">
              <div id="gameForm" class="control" style="margin-left:5rem">

                <div class="field is-horizontal">
                  <div class="field-label">
                    <label class="label has-tooltip-bottom" data-tooltip="Specify max number">Max:</label> 
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" v-model="game_maxNum">
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="field is-horizontal">
                  <div class="field-label">
                    <label class="label has-tooltip-bottom" data-tooltip="Specify number of operations in each problem">Num. Operators:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" v-model="game_numOps"/>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <p>Message is: {{ game_numOps }}, {{game_maxNum}}</p> -->

                <div class="field is-horizontal">
                  <div class="field-label">
                    <label class="label has-tooltip-bottom" data-tooltip="Select all allowed operators">Operators: </label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control is-size-3 has-text-left">
                        <input type="checkbox" id="add" value="+" v-model="game_checkedOperators"> <label for="add" >+</label>
                        <input type="checkbox" id="sub" value="-" v-model="game_checkedOperators" style="margin-left:3rem;"> <label for="sub">-</label>
                        <input type="checkbox" id="mul" value="*" v-model="game_checkedOperators" style="margin-left:3rem; "> <label for="mul">&times;</label>
                        <input type="checkbox" id="truediv" value="\" v-model="game_checkedOperators" style="margin-left:3rem;"> <label for="truediv">&divide;</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <span>Checked names: {{ game_checkedOperators }}</span> -->
              </div>
            </slot>
          </div>

          <div class="modal-card-footer">
            <slot name="footer">
              <button class="button is-success" v-on:click="emitForm" v-if="isSolo">
                Play
              </button>
              <button class="button is-success" v-on:click="emitForm" v-else>
                Challenge
              </button>
              <button class="button" @click="$emit('close')">
                Cancel
              </button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'arrangeFormModal',
  props: {
    challenged:{Object, required:true},
    isSolo: {type: Boolean, default:false},
  },
  data: function() {
    return { game_numOps:2, game_maxNum:20, game_checkedOperators:["+", "-"]}
  },
  methods: {    
    emitForm() {
      this.$emit('submittedArrangeOptions', {"u":this.challenged, "gameType":"Arrange", "maxNum": this.game_maxNum, "num_ops":this.game_numOps, "operators":this.game_checkedOperators})
    },
  }
}
</script>

<style>
input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
<template>
  <div :class="['card', 'm-2', rarity, finished ? 'finished': '']" v-bind:style="[showthumb ? {'width':'150px'} : {'width':'150px', 'height':'125px', 'background-image':'url('+getImgUrl(c.photo200)+')','background-size':'cover', 'background-color':'#999' }]" >

    <div class="has-tooltip-multiline has-tooltip-arrow" :data-tooltip="c.actionDesc">
      <div v-if="showthumb" class="card-image" >
        <figure class="image is-1by1"> <img v-bind:src="getImgUrl(c.photo200)"> </figure>
        <div class="card-content" style='background-color:#fff;opacity:70%' >
          <div class="media-content" >
            <p class="title is-6" style="height:42px;align-items:center">{{c.title}}</p>
            <p class="subtitle is-6" style="height:15px;align-items:center" >
              <span>{{c.action}} {{c.strength}} </span>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-content" style='background-color:#fff;opacity:25%' >
          <div class="media-content" >
            <p class="title is-6" style="height:42px;align-items:center"></p>
            <p class="subtitle is-6" style="height:15px;align-items:center" >
              <span></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer" style="height:48px">
      <a v-if="fremove" href="javascript:void(0)" class="card-footer-item has-tooltip-arrow" v-on:click="$emit('removeCard', index)" data-tooltip="Move to library">Remove</a>
      <a v-if="fhistory"  href="javascript:void(0)" class="card-footer-item has-tooltip-arrow" v-on:click="showHistory=true" data-tooltip="Click for card story">Story</a>
      <p v-if="fquant" class="card-footer-item">{{quant}}</p>
      <a v-if="ftake"  href="javascript:void(0)" class="card-footer-item has-tooltip-arrow" v-on:click="$emit('takeCard', cardid)" data-tooltip="Add to deck">+</a>
      <!--<button v-if="fuse"  href="javascript:void(0)" :class="[activeAction && !finished ? 'is-loading' : '', 'is-focused', 'button', colorClass, 'card-footer-item']" :disabled="!unused" v-on:click="useCard" ><span v-if="unused">Use</span><span v-if="finished">Used</span></button>-->

      <div v-if="fuse" class="card-footer-item">
        <aylTimer v-bind:end-time="finishTime" ref="timerp" :autoStart="false" v-on:finish='finishedTimer' >
         <template v-slot:before>
           <span v-if="status">
              <button class="button" disabled>
                {{status}}
              </button>
           </span>
           <span v-else>
              <button class="button" @click="useCardTimer" :disabled="opponents">
                <span v-if="c.name==='blank'">
                  Hidden
                </span>
                <span v-else>
                  {{c.action}} {{c.strength}} 
                </span>
              </button>
            </span>
         </template>
         <template v-slot:process="timer">
            <div style="width:90px">
            <progress :class="['progress', colorClass] " :value="fullTime - timer.timeObj.ceil.s" :max="fullTime"></progress>
            </div>
         </template>
         <template v-slot:finish>
           <button class="button" disabled >
            Used
           </button>
         </template>
        </aylTimer>
      </div>
    </footer>

    <div :class="[showHistory ? 'is-active' : '', 'modal']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{c.title}}</p>
          <button class="delete" aria-label="close" v-on:click="showHistory=false"></button>
        </header>
        <section class="modal-card-body">
           <figure class="image is-1by1"> <img v-bind:src="getImgUrl(c.photo)"> </figure>
           <div style="text-align:left" v-html="c.History"></div>
        </section>
        <footer class="modal-card-foot">
        </footer>
      </div>
    </div> 
  </div>
</template>
<script>

import aylTimer from '@/components/aylcount.vue'

export default {
  name: 'Card',  
  components: {
    aylTimer
  },
  data: function() { return { showHistory:false, unused: true, activeAction:false, fullTime: null, activeUI:false, status:null, finished:false } },
  props: {
    cardid: { type: String, required:true},
    index: { type: Number },
    quant: { type: Number },
    fremove: {type: Boolean, default:false},
    fquant: {type: Boolean, default:false},
    fhistory: {type: Boolean, default:false},
    ftake: {type: Boolean, default:false},
    fuse: {type: Boolean, default:false},
    showthumb: {type: Boolean, default:true},
    //finished: {type: Boolean, default:false},
    opponents: {type: Boolean, default:false},
    isHidden: {type: Boolean, default:false},
  },
  methods: {
    getImgUrl(pic) {
      return "https://mathleet.s3-us-west-2.amazonaws.com/card_images" + pic
    },
    getToolTip(c) {
      if (this.isHidden) {
        return "Unknown";
      } else {
        return c.actionDesc;
      }
    },
    useCard() {
      this.unused = false
      this.activeAction = true
      this.$emit('useCard', {"cardid": this.cardid, "index":this.index})
    },
    useCardTimer() {
      // console.log("in Card.vue/useCardTime:" +this.cardId +"; " +this.index +"; emits useCard to PlayGame.vue");
      this.$emit('useCard', {"cardid": this.cardid, "index":this.index})
    },
    finishedTimer() {
      this.finished = true
      this.$emit('finishedCard', {"cardid": this.cardid, "index":this.index})
      this.activeUI = false;
    },
    runTimer(totalT) {
      this.fullTime = totalT
      this.$refs.timerp.setEndTime(Date.now() + totalT * 1000)
      this.$refs.timerp.startCountdown()
      this.activeUI = true;
    },
    stopTimer() {
      //this.$refs.timerp.setEndTime(Date.now() - 1000);
      this.finished = true
      this.$refs.timerp.finishCountdown()
    },
    setStatus(s) {
      this.status = s;
    },
    setIsHidden(state) {  // boolean
      this.isHidden = state;
    },
  },
  computed: {
    c: function() { 
      return this.$store.state.carddb[this.cardid] 
    },
    finishTime: function() { 
      return Date.now() + 20000 
    },
    isAttack: function() {
      //return (this.c.action == "block")
      return (["freeze", "ambush"].indexOf(this.c.action)>-1);
    },
    isDefend: function() {
      //return (this.c.action == "defend manual")
      return (["reduce", "shield", "nullify"].indexOf(this.c.action) >-1);
    },
    colorClass: function() {
      if (["freeze", "ambush"].indexOf(this.c.action)>-1) {
        return "is-danger"
      } else if (["reduce", "shield", "nullify"].indexOf(this.c.action) >-1) {
        return "is-success"
      } else {
        return "is-info"
      }
    },
    rarity: function() {
      if (this.cardid == "agnesi") {
        return "common"
      } else if (this.cardid == "pythagoras") {
        return "rare"
      } else if (this.cardid == "hipparchos") {
        return "uncommon"
      } else {
        return "common"
      }
    },
  },
  created: function() {
  },
  mounted: function() {
    //console.log('in card mounted');
    this.$emit('mounted', {"cardid": this.cardid, "index":this.index});
    window._MathJax.typeset();
  },
}
</script>
<style>
.common {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 1.0), 0 0px 0 2px rgba(10, 10, 10, 0.02);
}
.common:hover {
  box-shadow: 0 0.75em 1.5em -.125em rgba(10, 10, 10, 1.0), 0 0px 0 2px rgba(10, 10, 10, 0.02);
  transition: all 0.5s;
}
.uncommon {
  box-shadow: 0 0.5em 1em -0.125em rgba(212,175,55, 1.0), 0 0px 0 2px rgba(212,175,55, 0.32);
}
.uncommon:hover {
  box-shadow: 0 0.75em 1.5em -0.125em rgba(212,175,55, 1.0), 0 0px 0 2px rgba(212,175,55, 0.32);
  transition: all 0.5s;
}
.rare {
  box-shadow: 0 0.5em 1em -0.125em rgba(96, 47, 107, 1.0), 0 0px 0 2px rgba(96,47,107, 0.32);
}
.rare:hover {
  box-shadow: 0 0.75em 1.5em -0.125em rgba(96, 47, 107, 1.0), 0 0px 0 2px rgba(96,47,107, 0.32);
  transition: all 0.5s;
}
.finished {
opacity: 50%
}
</style>

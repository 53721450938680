import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Profile from "../views/Profile.vue"
import Lobby from "../views/Lobby.vue"
import PlayGame from "../views/PlayGame.vue"
import CreateUser from "../views/CreateUser.vue"
import LeaderBoard from "../views/LeaderBoard.vue"
import MyStats from "../views/MyStats.vue"
import FakeStats from "../views/FakeStats.vue"
import NotFound from "../views/NotFound.vue"

import { authGuard } from "../auth/authGuard";
import { authGuard2 } from "../auth/authGuard2";

Vue.use(VueRouter)

const routes = [
  {
    path: "/fakeStats",
    name: "FakeStats",
    component: FakeStats
  },
  /*
  {
    path: '/',
    name: 'Home',
    component: Lobby,          
    beforeEnter: authGuard2
    //component: Home
  },
  */
  {
    path: '/',
    redirect: {"name":"lobby"}
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  /*
    {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue') // webpackChunkName: "about"
  },
  */
    {
      path: "/leaderBoard",
      name: "LeaderBoard",
      component: LeaderBoard
    },  
    {
      path: "/createUser",
      name: "createUser",
      component: CreateUser, 
      beforeEnter: authGuard
    },
    {
      path: "/lobby",
      name: "lobby",
      component: Lobby,          
      beforeEnter: authGuard2
    },
    {
      path: "/playGame",
      name: "playGame",
      component: PlayGame
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: authGuard
    },
    {
      path: "/myStats",
      name: "myStats",
      component: MyStats,
      beforeEnter: authGuard
    },
    { path: '/404', component: NotFound },  
    { path: '*', redirect: '/404' },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import d3 from 'd3'
import nv from 'nvd3'
import BaseChartMixin from './BaseChartMixin'

export default {
  name: 'LineChart',
  mixins: [BaseChartMixin],
  props: {
    xAxisLabel: {type: String},
    yAxisLabel: {type: String},
    xMinMax: {type: Array},
    yMinMax: {type: Array},
    xFormat: {type: [Function, String]},
    yFormat: {type: [Function, String]},
    colors: {type: Array, default: () => ['rgb(31, 119, 180)', 'rgb(255, 127, 14)', 'rgb(44, 160, 44)', 'rgb(174, 199, 232)', 'rgb(255, 187, 120)', 'rgb(152, 223, 138)']},
    toolTip: {type: [Function, String]}
  },
  mounted () {
    nv.addGraph(() => {
      const chart = nv.models.lineChart()
        .useInteractiveGuideline(true)
        .margin({top: 30, right: 20, bottom: 50, left: 60}) // nvd3 default
        .color(this.colors)

      const xaxis = chart.xAxis.showMaxMin(false)
      if (this.xFormat) {
        if (typeof(this.xFormat) === 'string') {
          xaxis.tickFormat(d3.format(this.xFormat))
        } else {
          xaxis.tickFormat(this.xFormat)
        }
      }

      const yaxis = chart.yAxis.showMaxMin(false)
      if (this.yFormat) {
        if (typeof(this.yFormat) === 'string') {
          yaxis.tickFormat(d3.format(this.yFormat))
        } else {
          yaxis.tickFormat(this.yFormat)
        }
      }
      
      xaxis.axisLabel(this.xAxisLabel)
      yaxis.axisLabel(this.yAxisLabel)
      chart.yDomain(this.yMinMax)
      chart.xDomain(this.xMinMax)
      
      // when useInteractiveGuideline(true) use chart.interactiveLayer.tooltip.contentGenerator
      // otherwise use chart.interactiveLayer.contentGenerator
      if (this.toolTip) {        
        if (typeof(this.toolTip) === 'string') {
          chart.interactiveLayer.tooltip.contentGenerator(function() { return this.toolTip});
        } else {
          chart.interactiveLayer.tooltip.contentGenerator(this.toolTip);
        }
      }

      this.redraw(chart)
      this.chartRef = chart
      nv.utils.windowResize(chart.update);
      return chart
    })
  }
}
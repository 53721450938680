import { getInstance } from "./index";

export const authGuard2 = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };
  
  /* not needed - as loginWithRedirect triggered with login button (App.vue)
  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }
  */

  // Watch for the loading property to change before we check isAuthenticated  
  //const login_wait_flag = sessionStorage.getItem("mathleet_wait_for_login");
  const login_wait_flag = localStorage.getItem("mathleet_wait_for_login");
  console.log("authguard2; " + login_wait_flag)
  if (authService.isAuthenticated) {    // already logged in
    return next();
  } else  if (login_wait_flag) {        // wait for login attempt completes
      authService.$watch("loading", loading => {
        if (loading === false) {
          return fn();
        }
      });
  } else {                              // anon access
    return next();
  }
};

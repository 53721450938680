<template>
  <div class="New User">
<form @submit="checkForm">
<div class="columns">
<div class="column is-one-third"> </div>
<div class="column is-one-third"> 
<div class="field">
  <label class="label">Pick handle</label>
  <div class="control has-icons-right">
    <input class="input " type="text" placeholder="" value="" v-model="handle">
    <span class="icon is-small is-right">
      <i class="fas fa-check"></i>
    </span>
    <p :class="[handleDone ? 'is-success' : '', 'help']">{{msg}}</p>
  </div> <!-- control -->
</div> <!-- field -->
<br/>
<div class="field">
  <label class="label">Birth Year</label>
  <div class="control">
    <div class="select">
      <select v-model="byear">
        <option value="0">Pick year:</option>
        <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
        <option>older</option>
      </select>
    </div> <!-- select -->
  </div> <!-- control -->
</div> <!-- field -->
<br/>
<div class="field">
  <label class="label">Country</label>
  <div class="control">
      <vue-country-code @onSelect="onSelect" :preferredCountries="['us']" :enabledCountryCode=false></vue-country-code>
  </div> <!-- control -->
</div> <!-- field -->
<br/>


<button :class="[loading ? 'is-loading' : '', 'button', 'is-link']" :disabled="notdone">Submit</button>
</div> <!-- column -->
<div class="column is-one-third"> </div>
</div>
</form> 
  </div>
</template>
<script>

export default {
  name: 'CreateUser',
  data: function() { return { handle: null, byear:"0", country: null, typingTimer: null, notdone:true, msg:"enter handle", handleDone:false, loading: false } },
  computed: {
    years () {
      const year = new Date().getFullYear() - 4
      return Array.from({length: year - 1980}, (value, index) => 1981 + index).reverse()
    }
  },
  created: function() {
    this.$socket.onmessage =  this.render
  },
  watch: {
    handle: function() {
      this.notdone = true
      clearTimeout(this.typingTimer);
      this.msg = "please finish typing"
      this.handleDone = false
      if (this.handle) {
        this.typingTimer = setTimeout(this.doneTyping, 1000);
      }
    },
    byear: function() {
      this.notdone = true
      console.log(this.byear)
      this.checkValid()
    }
  },
  methods: {
    doneTyping: function() {
      if (! this.handle.match("^[A-Za-z]")) {
        this.msg = "must start with a character!"
        return
      } else if (! this.handle.match("^[A-Za-z0-9]+$")) {
        this.msg = "only characters and numbers allowed!"
        return
      } else if ( this.handle.length < 3 || this.handle.length > 10) {
        this.msg = "length between 3 and 10!"
        return
      }
      this.msg = "checking..."
      this.$socket.sendObj({"method":"checkHandle", "payload": {"handle": this.handle}})
      console.log("checking if handle is taken: " + this.handle)
    },
    checkValid: function() {
      if (this.byear != "0" && this.handleDone) {
        this.notdone = false
      }
    },
    checkForm: function (e) {
      e.preventDefault();
      this.loading = true
      this.notdone = true
      const datum = {"email": this.$auth.user.email, "handle": this.handle, "byear": this.byear, "country":this.country}
      console.log(datum)
      this.$socket.sendObj({"method":"createUser", "payload": datum})
    },
    render: function(m) {
      m = JSON.parse(m.data)
      console.log(m)
      if (m.m == "handleChecked") {
        if (m.d) {
          this.msg = "available!"
          this.handleDone = true
          this.checkValid()
        } else {
          this.msg = "not available"
        }
      } else if (m.m == "createdUser") {
        if (m.d) {
          this.$router.push("/lobby")
        } else {
          this.msg = "Could not create user!"
          this.handleDone = false
          this.notdone = true
        }
      }
    },
    onSelect: function({name, iso2, dialCode}) { 
      console.log(name, iso2, dialCode)
      this.country = iso2
    }
  }
}
</script>

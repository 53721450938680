<template> 
  <div>
    <progress v-if="!dataLoaded" class="progress is-large is-primary" max="100">60%</progress>
    <div v-if="dataLoaded">
      <h2 class="has-text-weight-bold">Rating Progression</h2>
      <div id="elo_progression">
        <vn-line :model="eloProgression" :toolTip="eloTip" :x-format="formatDate" y-format=",f" :height="'500'" x-axis-label="Date" y-axis-label="Rating" :y-min-max="minMaxElo" :x-min-max="minMaxTime">
        </vn-line>
      </div>   

      <br><br>
      <h2 class="has-text-weight-bold">Minutes Played</h2>
      <div id="gameTimeSpent">
        <vn-multi-bar :model="getGameTimeSpent" :toolTip="activityTip" :x-format="formatDate" y-format=",f" :height="'500'" x-axis-label="Date" y-axis-label="Time (min)">
        </vn-multi-bar>
      </div>
    </div>
  </div>
</template>

<script>
import d3 from 'd3'

export default {
  name: 'MyStats',
  data: function () {
    return {
      dataLoaded: false,
      eloData: [],
      gameTimeData: [],
      minMaxElo: [0, 2500],
      minMaxTime: [1645050955273, 1645050955273]
    }
  },
  activated: function() {
    this.$socket.onmessage =  this.render
    this.$socket.sendObj({"method":"getMyStatsSummary", "payload": {"email": this.$auth.user.email}});

    clean_tooltips();
  }, 
  computed: {
    eloProgression: function() {
      return [
        {
          key: this.eloData.key,
          bar: this.eloData.bar,
          values: this.eloData.values,
        }
      ];      
    },
    getGameTimeSpent: function() {
      let barData = [];
      this.gameTimeData.forEach(function(item) {
        barData.push(
        {
          key:item.key, 
          bar:item.bar, 
          values:item.values.map(t => {
            return {
              label: t.x,
              value: t.y/60,
              z: t.z
            }
          })
        });
      });
      return barData
    },
  },
  methods: {
    getEloMinMax: function() {
      let elo_vals = [],
          time_vals = [];

      this.eloData.values.forEach(function(item) {     
        elo_vals.push(item.y);
        time_vals.push(item.x);
      });

      this.minMaxElo = [Math.min.apply(null, elo_vals) -50, Math.max.apply(null, elo_vals) + 50];
      this.minMaxTime = [Math.min.apply(null, time_vals) - 86400000, Math.max.apply(null, time_vals) + 86400000];
      console.log(this.minMaxElo +' ' + this.minMaxTime); 
    },
    render: function (m) {
      m = JSON.parse(m.data)
      console.log(m.m)

      if (m.m == "gotStatsSummary") {
        this.eloData = m.d.elo_day;
        this.getEloMinMax();

        this.gameTimeData = m.d.activity_day;
                
        this.dataLoaded = true;
      }
    },
    formatDate (d) {
      return d3.time.format('%x')(new Date(d));
    },
    toMinute (d) {
      return d/1000/60; // ms to minutes
    },
    eloTip(d) {
      const d_data = d.series[0].data,
            d_key  = d.series[0].key,
            d_colour = d.series[0].color;
            
      let tipTable = document.createElement("table");
      let tipHead = document.createElement("thead"),
          headTR = document.createElement("tr"),
          headTD = document.createElement("td"),
          headAtt = document.createAttribute("colspan"),
          headSty = document.createAttribute("style");
      headTD.innerHTML = "<span class='has-text-weight-semibold'>" + d3.time.format('%x')(new Date(d_data.x)) + "</span>";
      headAtt.value="3";
      headTD.setAttributeNode(headAtt);
      headSty.value = "text-align:center";
      headTD.setAttributeNode(headSty);
      headTR.append(headTD);
      tipHead.append(headTR);
      tipTable.append(tipHead);
      
      let keyColorDiv = document.createElement("div"),
          keyColorSty = document.createAttribute("style");
      keyColorSty.value="background-color: " + d_colour;
      keyColorDiv.setAttributeNode(keyColorSty);

      let ratingTR = document.createElement("tr"),
          ratingTD0 = document.createElement("td"),
          ratingTD1 = document.createElement("td"),
          ratingTD2 = document.createElement("td"),
          ratingAtt0 = document.createAttribute("class"),
          ratingAtt1 = document.createAttribute("class"),
          ratingAtt2 = document.createAttribute("class");
      ratingAtt0.value = "legend-color-guide";
      ratingTD0.setAttributeNode(ratingAtt0);
      ratingTD0.append(keyColorDiv);
      ratingTD1.innerHTML = d_key;
      ratingAtt1.value = "key";
      ratingTD1.setAttributeNode(ratingAtt1);
      ratingTD2.innerHTML = d_data.y;
      ratingAtt2.value = "value";
      ratingTD2.setAttributeNode(ratingAtt2);
      ratingTR.append(ratingTD0);
      ratingTR.append(ratingTD1);
      ratingTR.append(ratingTD2);

      let gamesTR = document.createElement("tr"),
          gamesTD0 = document.createElement("td"),
          gamesTD1 = document.createElement("td"),
          gamesTD2 = document.createElement("td"),
          gamesAtt1 = document.createAttribute("class"),
          gamesAtt2 = document.createAttribute("class");
      gamesTD0.innerHTML = "";
      gamesTD1.innerHTML = "Games Played";
      gamesAtt1.value = "key";
      gamesTD1.setAttributeNode(gamesAtt1);
      gamesTD2.innerHTML = d_data.z;
      gamesAtt2.value = "value";
      gamesTD2.setAttributeNode(gamesAtt2);
      gamesTR.append(gamesTD0);
      gamesTR.append(gamesTD1);
      gamesTR.append(gamesTD2);

      let tipBody = document.createElement("tbody");
      tipBody.append(ratingTR);
      tipBody.append(gamesTR);
      tipTable.append(tipBody);
      
      return tipTable.outerHTML;
    },
    activityTip(d) {
      const d_data = d.data,
            d_key  = d.data.key,
            d_colour = d.color;

      let tipTable = document.createElement("table");
      let tipHead = document.createElement("thead"),
          headTR = document.createElement("tr"),
          headTD = document.createElement("td"),
          headAtt = document.createAttribute("colspan"),
          headSty = document.createAttribute("style");
      headTD.innerHTML = "<span class='has-text-weight-semibold'>" + d3.time.format('%x')(new Date(d_data.label)) + "</span>";
      headAtt.value="3";
      headTD.setAttributeNode(headAtt);
      headSty.value = "text-align:center";
      headTD.setAttributeNode(headSty);
      headTR.append(headTD);
      tipHead.append(headTR);
      tipTable.append(tipHead);
      
      let keyColorDiv = document.createElement("div"),
          keyColorSty = document.createAttribute("style");
      keyColorSty.value="background-color: " + d_colour;
      keyColorDiv.setAttributeNode(keyColorSty);

      let ratingTR = document.createElement("tr"),
          ratingTD0 = document.createElement("td"),
          ratingTD1 = document.createElement("td"),
          ratingTD2 = document.createElement("td"),
          ratingAtt0 = document.createAttribute("class"),
          ratingAtt1 = document.createAttribute("class"),
          ratingAtt2 = document.createAttribute("class");
      ratingAtt0.value = "legend-color-guide";
      ratingTD0.setAttributeNode(ratingAtt0);
      ratingTD0.append(keyColorDiv);
      ratingTD1.innerHTML = d_key;
      ratingAtt1.value = "key";
      ratingTD1.setAttributeNode(ratingAtt1);
      ratingTD2.innerHTML = d3.format('.0f')(d_data.value) + ' min';
      ratingAtt2.value = "value";
      ratingTD2.setAttributeNode(ratingAtt2);
      ratingTR.append(ratingTD0);
      ratingTR.append(ratingTD1);
      ratingTR.append(ratingTD2);

      let gamesTR = document.createElement("tr"),
          gamesTD0 = document.createElement("td"),
          gamesTD1 = document.createElement("td"),
          gamesTD2 = document.createElement("td"),
          gamesAtt1 = document.createAttribute("class"),
          gamesAtt2 = document.createAttribute("class");
      gamesTD0.innerHTML = "";
      gamesTD1.innerHTML = "Games Played";
      gamesAtt1.value = "key";
      gamesTD1.setAttributeNode(gamesAtt1);
      gamesTD2.innerHTML = d_data.z;
      gamesAtt2.value = "value";
      gamesTD2.setAttributeNode(gamesAtt2);
      gamesTR.append(gamesTD0);
      gamesTR.append(gamesTD1);
      gamesTR.append(gamesTD2);

      let tipBody = document.createElement("tbody");
      tipBody.append(ratingTR);
      tipBody.append(gamesTR);
      tipTable.append(tipBody);
      
      return tipTable.outerHTML;
    }
  }
}

function clean_tooltips() {
  let  dom_tooltips = document.getElementsByClassName("nvtooltip");
  // console.log("in clean_tooltips: " + dom_tooltips.length)
  for (let i=0; i<dom_tooltips.length; i++) {
    // dom_tooltips[i].style.display = "none";
    dom_tooltips[i].remove();
  }
}
</script>